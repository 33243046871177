
import LandscapeIcon from '@mui/icons-material/Landscape';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import RoomIcon from '@mui/icons-material/Room';
import { Checkbox, FormControlLabel, FormGroup, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Typography } from "@mui/material";
import { MapStyle, WeatherGeoJson } from 'adoms-common-lib';
import React from 'react';
import { AmplifyGeoOptions } from '../../common/DroneGeoInformationUtil';

type DroneGeoInformationPopoverProps = {
    opendPopover: boolean
    setOpenedPopover: React.Dispatch<React.SetStateAction<boolean>>
    mapStyle: MapStyle
    setMapStyle: React.Dispatch<React.SetStateAction<MapStyle>>
    setGeoConfigMapStyle: React.Dispatch<React.SetStateAction<string | undefined>>
    geoConfig: AmplifyGeoOptions
    setWeatherGeoJson: React.Dispatch<React.SetStateAction<WeatherGeoJson | undefined>>
    setUpdateWeatherInfo: React.Dispatch<React.SetStateAction<boolean>>
    isDisplayRainInfo: boolean
    setDisplayRainInfo: React.Dispatch<React.SetStateAction<boolean>>
    isDisplayWindInfo: boolean
    setDisplayWindInfo: React.Dispatch<React.SetStateAction<boolean>>
    isDisplayGroundWaveGeoInformation: boolean
    setDisplayGroundWaveGeoInformation: React.Dispatch<React.SetStateAction<boolean>>
    isDisplaySatelliteGeoInformation: boolean
    setDisplaySatelliteGeoInformation: React.Dispatch<React.SetStateAction<boolean>>
    popoverAnchor: React.MutableRefObject<null>
};

export const DroneGeoInformationPopover: React.FC<DroneGeoInformationPopoverProps> = (props) => {

    /**
     * 地図の表示形式が変更された時のハンドラ
     * @param mapStyle 
     */
    const handleChangeMapStyle = () => {
        if (props.mapStyle === MapStyle.satellite) {
            props.setMapStyle(MapStyle.neutral);
            props.setGeoConfigMapStyle(props.geoConfig.maps?.neutral);
        } else if (props.mapStyle === MapStyle.neutral) {
            props.setMapStyle(MapStyle.satellite);
            props.setGeoConfigMapStyle(props.geoConfig.maps?.satellite);
        };
    };

    /**
     * 降水量の表示可否を切り替えた際のハンドラ
     * @param mapStyle 
     */
    const handleChangeRainDisplay = () => {
        props.setDisplayRainInfo(!props.isDisplayRainInfo);
        if (props.isDisplayRainInfo) {
            props.setDisplayRainInfo(false);
        } else {
            props.setDisplayRainInfo(true);
        };
    };

    /**
     * 風向・風速の表示可否を切り替えた際のハンドラ
     * @param mapStyle 
     */
    const handleChangeWindDisplay = () => {
        props.setDisplayWindInfo(!props.isDisplayWindInfo);
        if (props.isDisplayWindInfo) {
            props.setDisplayWindInfo(false);
        } else {
            props.setDisplayWindInfo(true);
        };
    };

    const mapListItemButton = (
        icon: React.ReactNode,
        title: string,
        changeHandler: () => void) => {
        return (
            <ListItemButton sx={{ padding: "0px 8px" }} onClick={changeHandler}>
                <ListItemIcon sx={{ minWidth: "30px" }}>
                    {icon}
                </ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{ variant: 'subtitle2' }}
                    primary={title} />
            </ListItemButton>
        );
    };

    return (
        <Popover
            id="mouse-over-popover"
            sx={{
                pointerEvents: 'none',
                '& .MuiPopover-paper': {
                    pointerEvents: 'auto',
                },
            }}
            open={props.opendPopover}
            anchorEl={props.popoverAnchor.current}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transitionDuration={0}
            PaperProps={{
                onMouseEnter: () => props.setOpenedPopover(true),
                onMouseLeave: () => props.setOpenedPopover(false)
            }}
        >
            <List dense sx={{ padding: "4px 0px" }}>
                {props.mapStyle === MapStyle.satellite ?
                    mapListItemButton(
                        <LandscapeIcon fontSize="small" />,
                        "地形",
                        handleChangeMapStyle
                    )
                    :
                    mapListItemButton(
                        <SatelliteAltIcon fontSize="small" />,
                        "衛星",
                        handleChangeMapStyle)
                }
                <ListItem sx={{ padding: "0px 8px" }}>
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                        <RoomIcon fontSize="small" />
                    </ListItemIcon>
                    <FormGroup row={true}>
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    size='small' 
                                    checked={props.isDisplayGroundWaveGeoInformation} 
                                    onChange={(event) => props.setDisplayGroundWaveGeoInformation(event.target.checked)} 
                                />} 
                            label={<Typography variant='subtitle2'>地上波</Typography>}
                        />
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    size='small'
                                    checked={props.isDisplaySatelliteGeoInformation} 
                                    onChange={(event) => props.setDisplaySatelliteGeoInformation(event.target.checked)} 
                                />
                            } 
                            label={<Typography variant='subtitle2'>衛星</Typography>}
                        />
                    </FormGroup>
                </ListItem>
            </List>
        </Popover>
    );
};