import AddCircle from '@mui/icons-material/AddCircle';
import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import FlightIcon from '@mui/icons-material/Flight';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveShoppingCart from '@mui/icons-material/RemoveShoppingCart';
import StoreIcon from '@mui/icons-material/Store';
import { Timeline, TimelineConnector, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { Alert, CircularProgress, Grid, SvgIcon, Typography } from '@mui/material';
import { AssosiateFlightAndOrder, EventName, FlightInfo, OperationHistory, OperationHistoryUtil, OrderInfo, RecordType, TimelineStatus, UserInfo } from "adoms-common-lib";
import React, { useEffect } from 'react';
import { ReactComponent as PackageCheckedIcon } from "../../assets/icons/package-variant-closed-check.svg";
import { ReactComponent as PackageIcon } from "../../assets/icons/package-variant-closed.svg";
import { ReactComponent as StoreClockIcon } from "../../assets/icons/store-clock.svg";
import { ReactComponent as CartCheckIcon } from "../../assets/icons/cart-check.svg";
import { APIConnector } from "../../connector/APIConnector";
import { FlightTimelineContent } from '../molecule/FlightTimelineContent';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';

type FlightTimelineProps = {
    flightId: string,
    orderId?: string
}

export type timelineComponent = {
    date: string,
    time: string,
    status: TimelineStatus,
    operation: string,
    recordType: RecordType,
    changedColumn?: string,
    assosiateFlightAndOrder?: AssosiateFlightAndOrder,
    newFlightInfo?: FlightInfo,
    oldFlightInfo?: FlightInfo,
    newOrderInfo?: OrderInfo,
    oldOrderInfo?: OrderInfo,
    updateUserInfo?: UserInfo
}

export const FlightTimeline: React.FC<FlightTimelineProps> = (props) => {
    const [timelineComponentList, setTimelineComponentList] = React.useState<timelineComponent[]>([] as timelineComponent[]);
    const [isDisplayLoadingMark, setDisplayLoadingMark] = React.useState<boolean>(false);
    const [operationHistoryError, setOperationHistoryError] = React.useState<string>();

    const isAllocationRecord = (history: OperationHistory): boolean => {
        return (
            history.id.startsWith("flight-") &&
            history.subId.startsWith("flight-") &&
            history.modifiedColumn?.length === 2 &&
            history.modifiedColumn?.includes("allocated") &&
            history.modifiedColumn?.includes("recent_update"));
    }

    useEffect(() => {
        const fetchData = async () => {
            let apigw: APIConnector = APIConnector.instance;
            setDisplayLoadingMark(true);
            await apigw.getHistoryForOperation(props.flightId, props.orderId).then((result) => {
                setOperationHistoryError(undefined);
                let timelineComponentList: timelineComponent[] = new Array();
                for (const operationHistory of result) {
                    if (isAllocationRecord(operationHistory)) {
                        //flightに対するallocatedの変更だけのレコードは無視する
                        continue;
                    }

                    let timelineComponent: timelineComponent;
                    let recordType: RecordType;
                    let timelineComponentStatus: TimelineStatus = TimelineStatus.Empty;
                    let timelineComponentOperation = "";
                    let timelineComponentNewFlightInfo: FlightInfo | undefined
                    let timelineComponentOldFlightInfo: FlightInfo | undefined
                    let timelineComponentNewOrderInfo: OrderInfo | undefined
                    let timelineComponentOldOrderInfo: OrderInfo | undefined
                    let timelineComponentAssosiateFlightAndOrder: AssosiateFlightAndOrder | undefined

                    // previousDate = date;
                    if (operationHistory.tableName.endsWith("-adoms-order-flight-junction")) {
                        recordType = OperationHistoryUtil.getRecordType(operationHistory);

                        // tableがadoms-flightの場合
                        if (EventName.INSERT === operationHistory.eventName) {
                            if (recordType === RecordType.Flight) {
                                // 新規フライトが作成された場合
                                timelineComponentStatus = TimelineStatus.Flight_NewFlight;
                                timelineComponentOperation = "フライト新規作成";
                                timelineComponentNewFlightInfo = operationHistory.newItem?.body as FlightInfo;
                            } else if (recordType === RecordType.FOJunction) {
                                //フライトに配送物が紐づけられた場合
                                timelineComponentStatus = TimelineStatus.Order_AddOrder;
                                timelineComponentOperation = "配送物割り当て";
                                timelineComponentAssosiateFlightAndOrder = {
                                    flightId: operationHistory.newItem?.id.replace("flight-", ""),
                                    orderId: operationHistory.newItem?.subId.replace("order-", ""),
                                } as AssosiateFlightAndOrder;
                            } else if (recordType === RecordType.Order) {
                                // 新規で配送依頼された場合
                                timelineComponentStatus = TimelineStatus.Order_NewOrder;
                                timelineComponentOperation = "配送依頼";
                                timelineComponentNewOrderInfo = operationHistory.newItem?.body as OrderInfo;
                            } else {
                                throw new Error("Invalid record");
                            };
                        } else if (EventName.MODIFY === operationHistory.eventName) {
                            // eventNameがMODIFYの場合
                            if (recordType === RecordType.Flight) {
                                if (operationHistory.modifiedColumn?.includes("flight-status")) {
                                    const afterChangeStatus = (operationHistory.newItem?.body as FlightInfo).status;

                                    timelineComponentOldFlightInfo = operationHistory.oldItem?.body as FlightInfo;
                                    timelineComponentNewFlightInfo = operationHistory.newItem?.body as FlightInfo;
                                    timelineComponentOperation = "フライトステータス『"
                                        + timelineComponentNewFlightInfo.status + "』に変更";

                                    if (afterChangeStatus?.includes(TimelineStatus.Flight_Standby)) {
                                        timelineComponentStatus = TimelineStatus.Flight_Standby
                                    } else if (afterChangeStatus?.includes(TimelineStatus.Flight_BeforePreparation)) {
                                        timelineComponentStatus = TimelineStatus.Flight_BeforePreparation
                                    } else if (afterChangeStatus?.includes(TimelineStatus.Flight_Preparation)) {
                                        timelineComponentStatus = TimelineStatus.Flight_Preparation
                                    } else if (afterChangeStatus?.includes(TimelineStatus.Flight_BeforeTakeoff)) {
                                        timelineComponentStatus = TimelineStatus.Flight_BeforeTakeoff
                                    } else if (afterChangeStatus?.includes(TimelineStatus.Flight_Takeoff)) {
                                        timelineComponentStatus = TimelineStatus.Flight_Takeoff
                                    } else if (afterChangeStatus?.includes(TimelineStatus.Flight_InFlightClimb)) {
                                        timelineComponentStatus = TimelineStatus.Flight_InFlightClimb
                                    } else if (afterChangeStatus?.includes(TimelineStatus.Flight_InFlightCruise)) {
                                        timelineComponentStatus = TimelineStatus.Flight_InFlightCruise
                                    } else if (afterChangeStatus?.includes(TimelineStatus.Flight_InFlightDescent)) {
                                        timelineComponentStatus = TimelineStatus.Flight_InFlightDescent
                                    } else if (afterChangeStatus?.includes(TimelineStatus.Flight_AfterLanding)) {
                                        timelineComponentStatus = TimelineStatus.Flight_AfterLanding
                                    } else if (afterChangeStatus?.includes(TimelineStatus.Flight_Completed)) {
                                        timelineComponentStatus = TimelineStatus.Flight_Completed
                                    } else if (afterChangeStatus?.includes(TimelineStatus.Flight_Reset)) {
                                        timelineComponentStatus = TimelineStatus.Flight_Reset
                                    } else if (afterChangeStatus?.includes(TimelineStatus.Flight_Irregular)) {
                                        timelineComponentStatus = TimelineStatus.Flight_Irregular
                                    } else if (afterChangeStatus?.includes(TimelineStatus.Flight_Canceled)) {
                                        timelineComponentStatus = TimelineStatus.Flight_Canceled
                                    };
                                };
                            } else if (recordType === RecordType.Order) {
                                if (operationHistory.modifiedColumn?.includes("order-receiveStatus")) {

                                    const afterChangeStatus = (operationHistory.newItem?.body as OrderInfo).receiveStatus;
                                    timelineComponentOldOrderInfo = operationHistory.oldItem?.body as OrderInfo;
                                    timelineComponentNewOrderInfo = operationHistory.newItem?.body as OrderInfo;
                                    timelineComponentOperation = "配送ステータス『" + timelineComponentNewOrderInfo?.receiveStatus + "』に変更";

                                    if (afterChangeStatus?.includes(TimelineStatus.Order_Received)) {
                                        timelineComponentStatus = TimelineStatus.Order_Received
                                    } else if (afterChangeStatus?.includes(TimelineStatus.Order_Loaded)) {
                                        timelineComponentStatus = TimelineStatus.Order_Loaded
                                    } else if (afterChangeStatus?.includes(TimelineStatus.Order_Preparation)) {
                                        timelineComponentStatus = TimelineStatus.Order_Preparation
                                    } else if (afterChangeStatus?.includes(TimelineStatus.Order_WaitingForPickup)) {
                                        timelineComponentStatus = TimelineStatus.Order_WaitingForPickup
                                    } else if (afterChangeStatus?.includes(TimelineStatus.Order_DepartForPort)) {
                                        timelineComponentStatus = TimelineStatus.Order_DepartForPort
                                    } else if (afterChangeStatus?.includes(TimelineStatus.Order_Cancel)) {
                                        timelineComponentStatus = TimelineStatus.Order_Cancel
                                    };
                                };
                            };
                        } else if (EventName.REMOVE === operationHistory.eventName) {
                            if (recordType === RecordType.FOJunction) {
                                // eventNameがREMOVEの場合
                                timelineComponentStatus = TimelineStatus.Order_RemoveOrder;
                                timelineComponentOperation = "配送物割り当て解除";
                                timelineComponentAssosiateFlightAndOrder = {
                                    flightId: operationHistory.oldItem?.id.replace("flight-", ""),
                                    orderId: operationHistory.oldItem?.subId.replace("order-", ""),
                                } as AssosiateFlightAndOrder;
                            } else {
                                throw new Error("Invalid record");
                            };
                        };
                    } else {
                        throw new Error("Invalid table");
                    };

                    const dateList = operationHistory.updateDateTime.match(/\d{4}-\d{2}-\d{2}/);
                    const date = dateList ? dateList[0] as string : undefined;
                    const timeList = operationHistory.updateDateTime.match(/\d{2}:\d{2}/);
                    const time = timeList ? timeList[0] as string : undefined;

                    timelineComponent = {
                        date: date ? date : "",
                        time: time ? time : "",
                        recordType: recordType,
                        status: timelineComponentStatus,
                        operation: timelineComponentOperation,
                        assosiateFlightAndOrder: timelineComponentAssosiateFlightAndOrder,
                        newFlightInfo: timelineComponentNewFlightInfo,
                        oldFlightInfo: timelineComponentOldFlightInfo,
                        newOrderInfo: timelineComponentNewOrderInfo,
                        oldOrderInfo: timelineComponentOldOrderInfo,
                        updateUserInfo: operationHistory.updateUserInfo
                    };
                    //暫定対応。対応していない変更履歴を画面に出力しない措置
                    if (timelineComponent.operation) {
                        timelineComponentList.push(timelineComponent);
                    };
                };

                // フライト新規作成と配送情報追加が同じ時間に実施された場合、
                // フライト新規作成⇒配送情報追加の順でタイムラインに表示されるように
                // timelineComponentListの順を入れ替える
                let newflightCreateTimelineComponent;
                for (let timelineComponent of timelineComponentList) {
                    if (timelineComponent.status === TimelineStatus.Flight_NewFlight) {
                        newflightCreateTimelineComponent = timelineComponent;
                    }
                }
                for (let timelineComponent of timelineComponentList) {
                    if (newflightCreateTimelineComponent
                        && timelineComponent.status === TimelineStatus.Order_AddOrder
                        && JSON.stringify(timelineComponent.date) === JSON.stringify(newflightCreateTimelineComponent.date)
                        && JSON.stringify(timelineComponent.time) === JSON.stringify(newflightCreateTimelineComponent.time)) {
                        if (timelineComponentList.indexOf(newflightCreateTimelineComponent)
                            > timelineComponentList.indexOf(timelineComponent)) {
                            timelineComponentList.splice(
                                timelineComponentList.indexOf(timelineComponent),
                                2,
                                newflightCreateTimelineComponent,
                                timelineComponent
                            );
                        }
                    }
                }
                setTimelineComponentList(timelineComponentList);
                setDisplayLoadingMark(false);
            }).catch((e) => {
                setOperationHistoryError("操作履歴を取得できませんでした")
                setDisplayLoadingMark(false);
            })
        }
        fetchData();
    }, []);

    const timelineIcon = (status: TimelineStatus, recordType: RecordType) => {
        let timelineIcon;
        if (recordType === RecordType.Flight) {
            if (TimelineStatus.Flight_NewFlight === status) {
                timelineIcon = (
                    <TimelineDot color="primary">
                        <FlightIcon
                            sx={{
                                transform: "rotate(90deg)"
                            }} />
                    </TimelineDot>
                )
            } else if (TimelineStatus.Flight_Standby === status
                || TimelineStatus.Flight_BeforePreparation === status
                || TimelineStatus.Flight_Preparation === status
                || TimelineStatus.Flight_BeforeTakeoff === status) {
                timelineIcon = (
                    <TimelineDot color="primary">
                        <StoreIcon />
                    </TimelineDot>
                )
            } else if (TimelineStatus.Flight_Takeoff === status
                || TimelineStatus.Flight_InFlightClimb === status) {
                timelineIcon = (
                    <TimelineDot color="primary">
                        <FlightTakeoffIcon />
                    </TimelineDot>
                )
            } else if (TimelineStatus.Flight_InFlightCruise === status) {
                timelineIcon = (
                    <TimelineDot color="primary">
                        <FlightIcon />
                    </TimelineDot>
                )
            } else if (TimelineStatus.Flight_InFlightDescent === status
                || TimelineStatus.Flight_AfterLanding === status) {
                timelineIcon = (
                    <TimelineDot color="primary">
                        <FlightLandIcon />
                    </TimelineDot>
                )
            } else if (TimelineStatus.Flight_Completed === status) {
                timelineIcon = (
                    <TimelineDot color="primary">
                        <DoneIcon />
                    </TimelineDot>
                )
            } else if (TimelineStatus.Flight_Reset === status) {
                timelineIcon = (
                    <TimelineDot color="primary">
                        <RefreshIcon />
                    </TimelineDot>
                )
            } else if (TimelineStatus.Flight_Irregular === status) {
                timelineIcon = (
                    <TimelineDot color="primary">
                        <PriorityHighIcon />
                    </TimelineDot>
                )
            } else if (TimelineStatus.Flight_Canceled === status) {
                timelineIcon = (
                    <TimelineDot color="primary">
                        <CancelIcon />
                    </TimelineDot>
                )
            };
        } else if (recordType === RecordType.FOJunction) {
            if (TimelineStatus.Order_AddOrder === status) {
                timelineIcon = (
                    <TimelineDot sx={{ backgroundColor: "#00695f" }}>
                        <AddShoppingCart />
                    </TimelineDot>
                )
            } else if (TimelineStatus.Order_RemoveOrder === status) {
                timelineIcon = (
                    <TimelineDot sx={{ backgroundColor: "#00695f" }}>
                        <RemoveShoppingCart />
                    </TimelineDot>
                )
            };
        } else if (recordType === RecordType.Order) {
            if (TimelineStatus.Order_NewOrder === status) {
                timelineIcon = (
                    <TimelineDot sx={{ backgroundColor: "#00695f" }}>
                        <SvgIcon>
                            <CartCheckIcon />
                        </SvgIcon>
                    </TimelineDot>
                )
            } else if (TimelineStatus.Order_WaitingForPickup === status) {
                timelineIcon = (
                    <TimelineDot sx={{ backgroundColor: "#00695f" }}>
                        <SvgIcon>
                            <PackageIcon />
                        </SvgIcon>
                    </TimelineDot>
                )
            } else if (TimelineStatus.Order_DepartForPort === status) {
                timelineIcon = (
                    <TimelineDot sx={{ backgroundColor: "#00695f" }}>
                        <EmojiTransportationIcon />
                    </TimelineDot>
                )
            } else if (TimelineStatus.Order_Preparation === status) {
                timelineIcon = (
                    <TimelineDot sx={{ backgroundColor: "#00695f" }}>
                        <SvgIcon>
                            <StoreClockIcon />
                        </SvgIcon>
                    </TimelineDot>
                )
            } else if (TimelineStatus.Order_Loaded === status) {
                timelineIcon = (
                    <TimelineDot sx={{ backgroundColor: "#00695f" }}>
                        <SvgIcon>
                            <PackageCheckedIcon />
                        </SvgIcon>
                    </TimelineDot>
                )
            } else if (TimelineStatus.Order_Received === status) {
                timelineIcon = (
                    <TimelineDot sx={{ backgroundColor: "#00695f" }}>
                        <SvgIcon>
                            <DoneIcon />
                        </SvgIcon   >
                    </TimelineDot>
                )
            } else if (TimelineStatus.Order_Cancel === status) {
                timelineIcon = (
                    <TimelineDot sx={{ backgroundColor: "#00695f" }}>
                        <CancelIcon />
                    </TimelineDot>
                )
            };
        };
        return timelineIcon;
    };

    return (
        <React.Fragment>
            {!isDisplayLoadingMark ?
                !operationHistoryError ?
                    <Timeline position="right" sx={{ paddingLeft: 0 }}>
                        {timelineComponentList.map((timelineComponent, i) => (
                            <TimelineItem >
                                <TimelineOppositeContent>
                                    <Typography variant="body2" color="inherit" sx={{ width: "36px" }}>
                                        {i == 0 || (i > 0 && JSON.stringify(timelineComponentList[i - 1].date) != JSON.stringify(timelineComponent.date))
                                            ? timelineComponent.date : null}
                                    </Typography>
                                    <Typography variant="body2" color="inherit">
                                        {timelineComponent.time}
                                    </Typography>
                                </TimelineOppositeContent >
                                <TimelineSeparator>
                                    {i == 0 ?
                                        null : <TimelineConnector />}
                                    {timelineIcon(timelineComponent.status, timelineComponent.recordType!)}
                                    {i == timelineComponentList.length - 1 ?
                                        null : <TimelineConnector />}
                                </TimelineSeparator>
                                <FlightTimelineContent timelineComponent={timelineComponent} />
                            </TimelineItem>
                        ))}
                    </Timeline> :
                    <Alert severity="error">{operationHistoryError}</Alert> :
                <Grid textAlign={"center"}>
                    <CircularProgress color="primary" />
                </Grid>}
        </React.Fragment>
    );
}