import { Alert, Card, CardContent, FormControl, Grid, Paper, TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FlightInfo } from "adoms-common-lib";
import { Dayjs } from 'dayjs';
import ja from 'dayjs/locale/ja';
import React from 'react';
import { DisplayInfoGrid } from '../atoms/DisplayInfoGrid';

type ReportCardProps = {
    flightInfo: FlightInfo
    squawkDate?: Dayjs | null
    changeSquawkDateValue: (event: any) => void
    flightSquawk?: string
    changeFlightSquawkTextfieldValue: (event: any) => void
    actionDate?: Dayjs | null
    changeActionDateValue: (event: any) => void
    correctiveAction?: string
    changeCorrectiveActionTextfieldValue: (event: any) => void
    confirmer?: string
    changeConfirmerTextfieldValue: (event: any) => void
    flightReportErrorMessage: string | undefined
}

export const ReportCardForFlightDetailView: React.FC<ReportCardProps> = (props) => {

    return (
        <Card>
            <CardContent>
                <Grid container direction="column" spacing={1} alignItems="stretch">
                    {props.flightReportErrorMessage ?
                        <Grid item>
                            <Alert severity="error">{props.flightReportErrorMessage}</Alert>
                        </Grid> : undefined
                    }
                    <Grid item container direction="row" spacing={1}>
                        <DisplayInfoGrid
                            xl={4} lg={4} md={4} sm={4} xs={12}
                            title="発生日時"
                            value={
                                <FormControl fullWidth>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            format="YYYY/MM/DD"
                                            value={props.squawkDate}
                                            onChange={props.changeSquawkDateValue}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            } />
                        <DisplayInfoGrid
                            xl={8} lg={8} md={8} sm={8} xs={12}
                            title="不具合事項"
                            value={
                                <TextField
                                    value={props.flightSquawk}
                                    onChange={event => { props.changeFlightSquawkTextfieldValue(event) }}
                                    multiline
                                    maxRows="2"
                                    variant="outlined"
                                    fullWidth
                                />
                            } />
                    </Grid>
                    <Grid item container direction="row" spacing={1}>
                        <DisplayInfoGrid
                            xl={4} lg={4} md={4} sm={4} xs={12}
                            title="処置年月日"
                            value={
                                <FormControl fullWidth>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            format="YYYY/MM/DD"
                                            value={props.actionDate}
                                            onChange={props.changeActionDateValue}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            } />
                        <DisplayInfoGrid
                            xl={8} lg={8} md={8} sm={8} xs={12}
                            title="処置その他"
                            value={
                                <TextField
                                    value={props.correctiveAction}
                                    onChange={event => { props.changeCorrectiveActionTextfieldValue(event) }}
                                    multiline
                                    maxRows="2"
                                    variant="outlined"
                                    fullWidth
                                />
                            } />
                        <DisplayInfoGrid
                            xl={4} lg={4} md={4} sm={4} xs={12}
                            title="確認者"
                            value={
                                <TextField
                                    value={props.confirmer}
                                    onChange={event => { props.changeConfirmerTextfieldValue(event) }}
                                    multiline
                                    maxRows="2"
                                    variant="outlined"
                                    fullWidth
                                />
                            } />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}