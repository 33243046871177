import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import PersonIcon from '@mui/icons-material/Person';
import ScheduleIcon from '@mui/icons-material/Schedule';
import StoreIcon from '@mui/icons-material/Store';
import { Alert, Avatar, Box, Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Display, OrderInfo } from "adoms-common-lib";
import React, { useEffect } from 'react';
import ReceiveStatusInfo from '../atoms/ReceiveStatusInfo';
import { OrderImageDialog } from '../molecule/OrderImageDialog';
import { ReceiveStatusChangeDialog } from '../molecule/ReceiveStatusChangeDialog';
import { blue } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';
import { OrderInfoEditDiglog } from '../molecule/OrderInfoEditDiglog';

type OrderInfoGridProps = {
    setSavedOrderInfo: React.Dispatch<React.SetStateAction<boolean>>
    orderInfo?: OrderInfo
};

export const OrderInfoGrid: React.FC<OrderInfoGridProps> = (props) => {

    const [displayOrderInfo, setDisplayOrderInfo] = React.useState<OrderInfo | undefined>
        (props.orderInfo ? props.orderInfo : undefined);
    const [isOrderImageDialogOpen, setOrderImageDialogOpen] = React.useState(false);
    const [isReceiveStatusDialogOpen, setReceiveStatusDialogOpen] = React.useState(false);
    const [isOrderInfoEditDialogOpen, setOrderInfoEditDialogOpen] = React.useState(false);

    useEffect(() => {
        setDisplayOrderInfo(props.orderInfo ? props.orderInfo : undefined);
    }, [props.orderInfo]);

    const orderInfoTypographyGrid = (icon: JSX.Element, title: string, subtitleValueList: { subtitle: string, value: string }[]) => {
        return (
            <Grid item container spacing={2} direction={"row"} flexWrap="nowrap">
                <Grid item paddingTop={2}>
                    {icon}
                </Grid>
                <Grid item container spacing={1} direction={"column"}>
                    <Grid item >
                        <Typography color="textSecondary" variant="subtitle1" sx={{ fontWeight: 'bold' }}>{title}</Typography>
                    </Grid>
                    <Grid item container spacing={1} direction={"column"}>
                        {subtitleValueList.map((subtitleValue, index) => (
                            <Grid item container spacing={1} direction={"row"} flexWrap="nowrap" key={index}>
                                <Grid item>
                                    <Typography width="120px"
                                        color="textSecondary"
                                        whiteSpace="pre-wrap">{subtitleValue.subtitle}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography whiteSpace="pre-wrap" sx={{ fontWeight: 'bold' }}>
                                        {subtitleValue.value}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        )
    };

    return (displayOrderInfo ?
        <React.Fragment>
            <Box height={`calc(100vh - 240px)`} overflow={"auto"} width="100%">
                <Grid container
                    spacing={2}
                    direction={"column"}
                    xs={12}
                    minHeight={"calc(100% + 16px)"}
                    paddingBottom="3px">
                    <Grid item>
                        <Grid container item xs={12} justifyContent="space-between">
                            <Grid item>
                                <Button sx={{ textTransform: 'none', padding: 0 }} onClick={() => setReceiveStatusDialogOpen(true)}>
                                    <ReceiveStatusInfo status={displayOrderInfo?.receiveStatus} isOkinawaBlood={displayOrderInfo.businessPartnerId === "OKINAWA_BLOOD"} />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={1} direction="row">
                                    <Grid item>
                                        <Tooltip title="配送情報変更">
                                            <IconButton
                                                sx={{ padding: 0 }}
                                                onClick={() => setOrderInfoEditDialogOpen(true)}>
                                                <Avatar variant="rounded" sx={{ bgcolor: blue[500] }}>
                                                    <EditIcon />
                                                </Avatar>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title="注文内容">
                                            <div>
                                                <IconButton
                                                    sx={{ padding: 0 }}
                                                    onClick={() => setOrderImageDialogOpen(true)}
                                                    disabled={displayOrderInfo.receiptFileName === undefined}>
                                                    <Avatar variant="rounded"
                                                        sx={{
                                                            bgcolor: displayOrderInfo.receiptFileName !== undefined ?
                                                                blue[500] : undefined
                                                        }}>
                                                        <InsertPhotoIcon />
                                                    </Avatar>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant='subtitle2' color="textSecondary" sx={{ marginTop: 1 }}>
                                {displayOrderInfo.businessPartnerId + " " + displayOrderInfo.orderID}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        {orderInfoTypographyGrid(
                            <ScheduleIcon sx={{ color: "#797979", marginTop: "3px" }} />,
                            "配送希望日時",
                            [{ subtitle: "日付", value: displayOrderInfo?.preferredDeliveryDate },
                            { subtitle: "時間帯", value: displayOrderInfo.preferredDeliveryTime }]
                        )}
                        {orderInfoTypographyGrid(
                            <PersonIcon sx={{ color: "#797979", marginTop: "3px" }} />,
                            "お客様情報",
                            [{
                                subtitle: "氏名",
                                value: displayOrderInfo.destination.lastname + " " + displayOrderInfo.destination.firstname
                            },
                            {
                                subtitle: "電話番号", value: (displayOrderInfo.destination.tel && displayOrderInfo.destination?.tel !== "")
                                    ? displayOrderInfo.destination.tel : "設定なし"
                            },
                            {
                                subtitle: "住所", value: !displayOrderInfo.destination.prefecture
                                    && !displayOrderInfo.destination.city
                                    && !displayOrderInfo.destination.address
                                    ? "設定なし"
                                    : displayOrderInfo.destination.prefecture
                                    + displayOrderInfo.destination.city
                                    + displayOrderInfo.destination.address
                            }]
                        )}
                        {orderInfoTypographyGrid(
                            <StoreIcon sx={{ color: "#797979", marginTop: "3px" }} />,
                            "店舗情報",
                            [{
                                subtitle: "氏名",
                                value: (displayOrderInfo.requester.lastname && displayOrderInfo.requester.firstname)
                                    ? displayOrderInfo.requester.lastname + " " + displayOrderInfo.requester.firstname : "設定なし"
                            },
                            {
                                subtitle: "電話番号",
                                value: (displayOrderInfo.destination.tel2 && displayOrderInfo.destination?.tel2 !== "")
                                    ? displayOrderInfo.destination.tel2 : "設定なし"
                            }]
                        )}
                        {orderInfoTypographyGrid(
                            <LocalGroceryStoreIcon sx={{ color: "#797979", marginTop: "3px" }} />,
                            "商品情報",
                            [{ subtitle: "重量", value: String(displayOrderInfo.goods[0].weight) + "g" }]
                        )}
                        {orderInfoTypographyGrid(
                            <InfoOutlinedIcon sx={{ color: "#797979", marginTop: "3px" }} />,
                            "その他",
                            [{ subtitle: "配送依頼日", value: Display.getDateStringFromISO8601(displayOrderInfo.orderDate) },
                            {
                                subtitle: "お客様共有備考", value: (displayOrderInfo.remarks && displayOrderInfo.remarks !== "")
                                    ? displayOrderInfo.remarks : "設定なし"
                            }
                            ]
                        )}
                    </Grid>
                </Grid>
            </Box>
            <ReceiveStatusChangeDialog
                displayOrderInfo={displayOrderInfo}
                isReceiveStatusDialogOpen={isReceiveStatusDialogOpen}
                setReceiveStatusDialogOpen={setReceiveStatusDialogOpen}
                setSavedOrderInfo={props.setSavedOrderInfo}
            />
            <OrderImageDialog
                displayOrderInfo={displayOrderInfo}
                isOrderImageDialogOpen={isOrderImageDialogOpen}
                setOrderImageDialogOpen={setOrderImageDialogOpen}
            />
            <OrderInfoEditDiglog
                displayOrderInfo={displayOrderInfo}
                isOrderInfoEditDialogOpen={isOrderInfoEditDialogOpen}
                setOrderInfoEditDialogOpen={setOrderInfoEditDialogOpen}
                setSavedOrderInfo={props.setSavedOrderInfo}
            />
        </React.Fragment>
        :
        <Grid item xs={12}>
            <Alert severity="info" >配送情報がありません</Alert>
        </Grid>
    )
};