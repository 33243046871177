import { Alert, Backdrop, Breadcrumbs, CircularProgress, Container, Grid, Paper, Tab, Tabs, TextField } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import { AssosiateFlightAndOrder, Display, OrderInfo, ReceiveStatus } from 'adoms-common-lib';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TabPanel } from '../../../components/atoms/TabPanel';
import { NotificationWarning } from '../../../components/molecule/NotificationWarning';
import { OrderCardForOrderListView } from '../../../components/molecule/OrderCardForOrderListView';
import { SelectDayButton } from '../../../components/molecule/SelectDayButton';
import OperationMenuBar from '../../../components/organisms/OperationMenuBar';
import { APIConnector } from '../../../connector/APIConnector';
const drawerWidth = 260;

const useStyles = makeStyles()((theme: any) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.default,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  deliveryTable: {
    minWidth: 1024,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  cardHeader: {
    backgroundColor: "#00695f"
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));


/**
 * 配送依頼リストからフィルタされたステータスのみを取得する。
 * @param orderList 
 * @param filter 
 */
function filterOrder(orderList: OrderInfo[], filter: ReceiveStatus[]) {
  return orderList.filter(order => filter.includes(order.receiveStatus));
}



/**
 * 配送依頼一覧
 */
export default function OrderListView() {
  const navigate = useNavigate();
  const { classes, cx } = useStyles();

  const [isOpen, setOpen] = React.useState(false);
  const [orderList, setOrder] = React.useState<OrderInfo[]>([]);
  const [assosiateFlightAndOrderList, setAssosiateFlightAndOrderList] = React.useState<AssosiateFlightAndOrder[]>([]);
  const [wasLoadedOrderData, setWasLoadedOrderData] = React.useState(false);
  const [canDisplayOrderData, setCanDisplayOrderData] = React.useState(false);
  const [isCorrectOrderDateISO8601DateFormat, setIsCorrectOrderDateISO8601DateFormat] = React.useState(true);
  const [orderDate, setOrderDate] = React.useState(Display.getNowDateWithString());
  const [tabValue, setTabValue] = React.useState(0);

  //配送依頼情報を取得
  useEffect(() => {
    setWasLoadedOrderData(false);
    const fetchOrderData = async () => {
      let c: APIConnector = APIConnector.instance;

      //orderListを元に中間テーブルから連携情報を取得
      await c.getOrderListByOrderDate(orderDate).then(async (orderList) => {
        setOrder(orderList);
        let tempAssosiateFlightAndOrderList: AssosiateFlightAndOrder[] = [];
        let assosiateFlightAndOrderPromise: Promise<AssosiateFlightAndOrder[]>[] = [];
        orderList.forEach((order) => {
          assosiateFlightAndOrderPromise.push(c.getFlightIdTiedToOrderId(order.orderID));
        })
        await Promise.all(assosiateFlightAndOrderPromise).then(array => {
          array.forEach(value => {
            tempAssosiateFlightAndOrderList = tempAssosiateFlightAndOrderList.concat(value);
          });
          setAssosiateFlightAndOrderList(tempAssosiateFlightAndOrderList)
        })
        setCanDisplayOrderData(true);
      }).catch((error) => {
        console.log(error);
        if (axios.isAxiosError(error)
          && typeof error.response !== "undefined"
          && error.response.status === 403) {
          handleToForbidden403Page();
        } else {
          setCanDisplayOrderData(false);
          setOrder([] as OrderInfo[]);
        };
      });

      setWasLoadedOrderData(true);
    }

    let isCorrectDateFormat = RegExp(/\d{4}-\d{2}-\d{2}/).test(orderDate);
    setIsCorrectOrderDateISO8601DateFormat(isCorrectDateFormat);
    /*
     React は再レンダー間で setState 関数の同一性が保たれ、変化しないため、
     useEffect内でsetした直後のisCorrectOrderDateISO8601DateFormatの値は参照できない
    */
    if (isCorrectDateFormat) {
      fetchOrderData();
    }
  }, [orderDate]);

  /**
   * メニューバーが開かれたときのハンドラ
   */
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  /**
   * メニューバーが閉じられた時のハンドラ
   */
  const handleDrawerClose = () => {
    setOpen(false);
  };

  /**
  * 配送依頼日が変わった時のハンドラ
  * @param event 
  */
  const handleChangeOrderDate = (event: any) => {
    setOrderDate(event.target.value);
  };

  /**
   * 配送依頼表示用コンポーネント（配送依頼0件、もしくはエラー）
   */
  const displayNoticeForNoOrderData = () => {
    let displayNoticeForNoOrderDataHTML;
    if (wasLoadedOrderData) {
      if (!canDisplayOrderData) {
        displayNoticeForNoOrderDataHTML =
          <Alert severity="error" style={{ marginTop: "15px" }}>配送依頼を取得できませんでした</Alert>
      } else if (filterOrder(orderList, [ReceiveStatus.Preparation, ReceiveStatus.DepartForPort, ReceiveStatus.Loaded, ReceiveStatus.NotReceived, ReceiveStatus.WaitingForPickup]).length === 0) {
        displayNoticeForNoOrderDataHTML =
          <Alert severity="info" style={{ marginTop: "15px" }}>現在オペレーション中の配送依頼はありません</Alert>
      }
    }
    return displayNoticeForNoOrderDataHTML;
  };

  /**
   * 403ページに遷移させる
   * @param navigate 
   */
  const handleToForbidden403Page = () => {
    navigate(
      "/forbidden403Page",
    );
  };

  /**
  * tabのidをセットする
  */
  const a11yProps = (index: any) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  };

  /**
   * タブが変更された時のハンドラ
   */
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      {/**メニューバーを表示する */}
      <OperationMenuBar
        onChangeDrawerOpen={handleDrawerOpen}
        onChangeDrawerClose={handleDrawerClose}
        open={isOpen}
        title="配送依頼一覧"
        bgcolor="#00695f"
      />

      {/** コンテンツ部分 */}
      <main
        className={cx(classes.content, {
          /** メニューバーがオープン・クローズされたときのスタイルの変更*/
          [classes.contentShift]: isOpen,
        })}
      >
        <div className={classes.drawerHeader} />

        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary" aria-current="page" variant="h5">
            配送依頼一覧
          </Typography>
        </Breadcrumbs>

        {
          /* 今後未来の配送依頼を参照する可能性を考慮し、
            実装を途中で終了したため、
            配送依頼に日付選択または入力で未来を選択できる状況を抑止していない。
          */
        }
        <br />
        <NotificationWarning />
        <TextField
          variant="standard"
          id="date"
          label="配送希望日"
          type="date"
          value={orderDate}
          className={classes.textField}
          onChange={handleChangeOrderDate}
          InputLabelProps={{
            shrink: true,
          }}
          error={!isCorrectOrderDateISO8601DateFormat}
          helperText={isCorrectOrderDateISO8601DateFormat ? '' : '正しい日付を入力してください'} />
        <br />
        <br />
        <SelectDayButton
          wasLoadedData={wasLoadedOrderData}
          date={orderDate}
          setDate={setOrderDate}
          color="#00695f"
        />
        <br />
        <br />
        <Grid container justifyContent="center" spacing={1} alignItems="stretch">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Paper>
              <Paper square>
                <Tabs
                  indicatorColor="primary"
                  textColor="inherit"
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: '#00695f'
                    }
                  }}
                  style={{
                    color: '#00695f'
                  }}
                  variant="fullWidth"
                >
                  <Tab label="配送中" {...a11yProps(0)} />
                  <Tab label="配送完了" {...a11yProps(1)} />
                </Tabs>
              </Paper>
              <TabPanel value={tabValue} index={0}>
                <Container maxWidth="md" component="main" style={{ marginRight: "auto" }}>
                  <Grid container spacing={2} alignItems="flex-end">
                    {filterOrder(orderList, [ReceiveStatus.Loaded, ReceiveStatus.DepartForPort, ReceiveStatus.Preparation, ReceiveStatus.NotReceived, ReceiveStatus.WaitingForPickup]).map((order, i) => (
                      <OrderCardForOrderListView
                        key={order.orderID}
                        order={order}
                        assosiateFlightAndOrderList={assosiateFlightAndOrderList}
                      />
                    ))}

                  </Grid>
                </Container>
                {displayNoticeForNoOrderData()}
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Container maxWidth="md" component="main">
                  <Grid container spacing={5} alignItems="flex-end">
                    {filterOrder(orderList, [ReceiveStatus.Received, ReceiveStatus.Cancel]).map((order, i) => (
                      <OrderCardForOrderListView
                        key={order.orderID}
                        order={order}
                        assosiateFlightAndOrderList={assosiateFlightAndOrderList}
                      />
                    ))}
                  </Grid>
                </Container>
              </TabPanel>
            </Paper>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            {/**右半分に配送詳細やMMセンサーの情報等を載せたい */}
          </Grid>
        </Grid>

        <Backdrop
          className={classes.backdrop}
          open={!wasLoadedOrderData}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </main>
    </div >
  );
}