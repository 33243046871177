import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TimelineContent } from '@mui/lab';
import { Accordion, AccordionDetails, AccordionSummary, Box, Paper, Typography } from '@mui/material';
import { AssosiateFlightAndOrder, FlightInfo, OrderInfo, RecordType, TimelineStatus } from "adoms-common-lib";
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { timelineComponent } from '../organisms/FlightTimeline';

const useStyles = makeStyles()((theme: any) => ({
    paper: {
        padding: '6px 16px',
        display: 'inline-block',
        minWidth: "200px"
    },
    userInfoTypography: {
        wordWrap: "break-word",
        color: theme.palette.text.secondary
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
        marginRight: theme.spacing(1),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

type FlightTimelineContentProps = {
    timelineComponent: timelineComponent
}

export const FlightTimelineContent: React.FC<FlightTimelineContentProps> = (props) => {
    const { classes } = useStyles();
    const [openAccordion, setOpenAccordion] = React.useState(false);

    const timelineDetailLine = (title: string, value: string) => {
        return (
            <React.Fragment>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }} >{title}</Typography>
                <Typography variant="subtitle2" sx={{ marginBottom: 1 }}>{value}</Typography>
            </React.Fragment>
        )
    }

    const timelineFlightDetail = (
        status: TimelineStatus,
        newFlightInfo?: FlightInfo,
        oldFlightInfo?: FlightInfo,
    ) => {
        let timelineOperationDetail;
        if (TimelineStatus.Flight_NewFlight === status) {
            if (newFlightInfo) {
                timelineOperationDetail = (
                    <Paper elevation={0} variant="outlined" sx={{ padding: 1 }}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', marginBottom: 2 }}>フライト情報</Typography>
                        {timelineDetailLine("フライトID", newFlightInfo.id)}
                        {timelineDetailLine("フライトステータス", newFlightInfo.status)}
                        {timelineDetailLine("使用機材", newFlightInfo.drone.label)}
                        {timelineDetailLine("最大ペイロード", newFlightInfo.drone.payload.toString() + "g")}
                        {timelineDetailLine("区間", newFlightInfo.departure.name + "⇒" + newFlightInfo.arrival.name)}
                        {timelineDetailLine("STD - STA", newFlightInfo.std + "-" + newFlightInfo.sta)}
                        {timelineDetailLine("ETD - ETA", newFlightInfo.etd + "-" + newFlightInfo.eta)}
                        {timelineDetailLine("フライトディレクター", newFlightInfo.fd.name)}
                    </Paper>
                );
            };
        } else if (TimelineStatus.Flight_Standby === status || TimelineStatus.Flight_BeforePreparation === status
            || TimelineStatus.Flight_Preparation === status || TimelineStatus.Flight_BeforeTakeoff === status
            || TimelineStatus.Flight_Takeoff === status || TimelineStatus.Flight_InFlightClimb === status
            || TimelineStatus.Flight_InFlightCruise === status || TimelineStatus.Flight_InFlightDescent === status
            || TimelineStatus.Flight_AfterLanding === status || TimelineStatus.Flight_Completed === status
            || TimelineStatus.Flight_Reset === status || TimelineStatus.Flight_Irregular === status
            || TimelineStatus.Flight_Canceled === status) {
            if (newFlightInfo && oldFlightInfo) {
                timelineOperationDetail = (
                    <Paper elevation={0} variant="outlined" sx={{ padding: 1 }}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', marginBottom: 2 }}>フライトステータス</Typography>
                        <Typography variant="subtitle2" >{oldFlightInfo.status}</Typography>
                        <Typography variant="subtitle2" >⇓</Typography>
                        <Typography variant="subtitle2" >{newFlightInfo.status}</Typography>
                    </Paper>
                );
            };
        };
        return timelineOperationDetail;
    };

    const timelineOrderDetail = (
        status: TimelineStatus,
        assosiateFlightAndOrder?: AssosiateFlightAndOrder,
        newOrderInfo?: OrderInfo,
        oldOrderInfo?: OrderInfo
    ) => {
        let timelineOperationDetail;
        if (TimelineStatus.Order_AddOrder === status || TimelineStatus.Order_RemoveOrder === status) {
            if (assosiateFlightAndOrder) {
                timelineOperationDetail = (
                    <Paper elevation={0} variant="outlined" sx={{ padding: 1 }}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', marginBottom: 2 }}>配送情報</Typography>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }} >確認番号</Typography>
                        <Typography variant="subtitle2" >{assosiateFlightAndOrder.orderId}</Typography>
                    </Paper>
                );
            };
        } else if (TimelineStatus.Order_NewOrder === status) {
            if (newOrderInfo) {
                timelineOperationDetail = (
                    <Paper elevation={0} variant="outlined" sx={{ padding: 1 }}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', marginBottom: 2 }}>配送情報</Typography>
                        {timelineDetailLine("配送ID", newOrderInfo.orderID)}
                        {timelineDetailLine("配送ステータス", newOrderInfo.receiveStatus)}
                        {timelineDetailLine("区間", newOrderInfo.departure.name + "⇒" + newOrderInfo.arrival.name)}
                    </Paper>
                );
            };
        } else if (TimelineStatus.Order_WaitingForPickup === status || TimelineStatus.Order_Preparation === status
            || TimelineStatus.Order_Loaded === status || TimelineStatus.Order_Received === status
            || TimelineStatus.Order_DepartForPort === status || TimelineStatus.Order_Cancel === status) {
            if (newOrderInfo && oldOrderInfo) {
                timelineOperationDetail = (
                    <Paper elevation={0} variant="outlined" sx={{ padding: 1 }}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', marginBottom: 2 }}>配送ステータス</Typography>
                        <Typography variant="subtitle2" >{oldOrderInfo.receiveStatus}</Typography>
                        <Typography variant="subtitle2" >⇓</Typography>
                        <Typography variant="subtitle2" >{newOrderInfo.receiveStatus}</Typography>
                    </Paper>
                );
            };
        };
        return timelineOperationDetail;
    };

    const handleAccordionChange = () => {
        setOpenAccordion(!openAccordion);
    }

    const timelineOperationUser = () => {
        if (props.timelineComponent.updateUserInfo?.name) {
            return <Typography className={classes.secondaryHeading}>{props.timelineComponent.updateUserInfo?.name}</Typography>
        } else if (props.timelineComponent.status === TimelineStatus.Order_Received
        ) {
            if (props.timelineComponent.newOrderInfo?.destination.lastname
                && props.timelineComponent.newOrderInfo?.destination.firstname) {
                return <Typography className={classes.secondaryHeading}>
                    {props.timelineComponent.newOrderInfo?.destination.lastname + " "
                        + props.timelineComponent.newOrderInfo?.destination.firstname + " 様"}</Typography>
            };
        } else if (props.timelineComponent.status === TimelineStatus.Order_NewOrder
            || props.timelineComponent.status === TimelineStatus.Order_DepartForPort) {
            if (props.timelineComponent.newOrderInfo?.requester.lastname
                && props.timelineComponent.newOrderInfo?.requester.firstname) {
                return <Typography className={classes.secondaryHeading}>
                    {props.timelineComponent.newOrderInfo?.requester.lastname + " "
                        + props.timelineComponent.newOrderInfo?.requester.firstname + " 様"}</Typography>
            };
        }
    };

    return (
        <TimelineContent>
            <Accordion expanded={openAccordion} onChange={handleAccordionChange} sx={{ width: "200px" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Box sx={{ wordBreak: "break-all" }}>
                        <Typography className={classes.heading}><b >{props.timelineComponent.operation}</b ></Typography>
                        {timelineOperationUser()}
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {props.timelineComponent.recordType === RecordType.Flight ? timelineFlightDetail(
                            props.timelineComponent.status,
                            props.timelineComponent.newFlightInfo,
                            props.timelineComponent.oldFlightInfo) :
                            undefined}
                        {props.timelineComponent.recordType === RecordType.Order
                            || props.timelineComponent.recordType === RecordType.FOJunction ? timelineOrderDetail(
                                props.timelineComponent.status,
                                props.timelineComponent.assosiateFlightAndOrder,
                                props.timelineComponent.newOrderInfo,
                                props.timelineComponent.oldOrderInfo) :
                            undefined}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </TimelineContent>
    )
};