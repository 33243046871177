import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import EditIcon from '@mui/icons-material/Edit';
import FlightIcon from '@mui/icons-material/Flight';
import HistoryIcon from '@mui/icons-material/History';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NotesIcon from '@mui/icons-material/Notes';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Alert, Avatar, Box, Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import { FlightInfo, FunctionPrivilege, OrderInfo, Report, UserInfo } from "adoms-common-lib";
import React from 'react';
import { havePrivilege } from '../../common/PrivilegeUtil';
import FlightStatusInfo, { StatusSize } from '../atoms/FlightStatusInfo';
import { FlightInfoEditDialog } from '../molecule/FlightInfoEditDialog';
import { FlightReportDialog } from '../molecule/FlightReportDialog';
import { FlightStatusChangeDialog } from '../molecule/FlightStatusChangeDialog';
import { FlightTimelineDialog } from './FlightTimelineDialog';

type FlightInfoGridProps = {
    selectedFlightInfo: FlightInfo | undefined
    setSavedFlightInfo: React.Dispatch<React.SetStateAction<boolean>>
    userInfo: UserInfo | undefined
    orderInfo?: OrderInfo
};

export type FlightSchedule = {
    etd?: string,
    eta?: string,
};

export type FlightScheduleChange = {
    flightSchedule?: FlightSchedule,
    remarks?: string,
    unsafeEvent?: string,
    report?: Report
};

/**
 * フライト情報表示
 */
export const FlightInfoGrid: React.FC<FlightInfoGridProps> = (props) => {

    const [isFlightStatusDialogOpen, setFlightStatusDialogOpen] = React.useState(false);
    const [isFlightReportDialogOpen, setFlightReportDialogOpen] = React.useState(false);
    const [isFlightInfoEditDialogOpen, setFlightInfoEditDialogOpen] = React.useState(false);
    const [isFlightTimelineDialogOpen, setFlightTimelineDialogOpen] = React.useState(false);

    const flightInfoTypographyGrid = (icon: any, titleValueList: { title: string, value: string }[]) => {
        return (
            <Grid item container spacing={2} direction={"row"} flexWrap="nowrap">
                <Grid item>
                    {icon}
                </Grid>
                <Grid item container direction={"column"} spacing={2}>
                    {titleValueList.map((titleValue, index) => (
                        <Grid item container spacing={2} direction={"row"} flexWrap='nowrap' key={index}>
                            <Grid item>
                                <Typography width="120px" color="textSecondary">{titleValue.title}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography sx={{ fontWeight: 'bold' }}>{titleValue.value}</Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        )
    };

    const flightInfoSentenceGrid = (icon: any, title: string, value?: string) => {
        if (!value) {
            value = "設定なし"
        }
        return (
            <Grid item container spacing={2} direction={"row"} flexWrap="nowrap">
                <Grid item>
                    {icon}
                </Grid>
                <Grid item container direction={"column"} spacing={1}>
                    <Grid item>
                        <Typography color="textSecondary">{title}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography sx={{ fontWeight: 'bold' }}>{value}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    };

    return (
        props.selectedFlightInfo ?
            <React.Fragment>
                <Box height={`calc(100vh - 240px)`} overflow={"auto"}>
                    <Grid container
                        spacing={2}
                        direction={"column"}
                        xs={12}
                        minHeight={"calc(100% + 16px)"}
                        paddingBottom="3px">
                        {havePrivilege(FunctionPrivilege.PrivilegeFunctionChangeFlightStatus, props.userInfo) ?
                            <Grid container item spacing={1} direction="column">
                                <Grid container item xs={12} justifyContent="space-between">
                                    <Grid item>
                                        <Button sx={{ textTransform: 'none', padding: 0 }} onClick={() => setFlightStatusDialogOpen(true)}>
                                            <FlightStatusInfo
                                                status={props.selectedFlightInfo.status}
                                                statusSize={StatusSize.Medium} />
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={1} direction="row">
                                            <Grid item>
                                                <Tooltip title="フライト情報変更">
                                                    <IconButton
                                                        sx={{ padding: 0 }}
                                                        onClick={() => setFlightInfoEditDialogOpen(true)}>
                                                        <Avatar variant="rounded" sx={{ bgcolor: blue[500] }}>
                                                            <EditIcon />
                                                        </Avatar>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title="フライトレポート">
                                                    <IconButton
                                                        sx={{ padding: 0 }}
                                                        onClick={() => setFlightReportDialogOpen(true)}>
                                                        <Avatar variant="rounded" sx={{ bgcolor: blue[500] }}>
                                                            <ContentPasteIcon />
                                                        </Avatar>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title="フライト変更履歴">
                                                    <IconButton
                                                        sx={{ padding: 0 }}
                                                        onClick={() => setFlightTimelineDialogOpen(true)}>
                                                        <Avatar variant="rounded" sx={{ bgcolor: blue[500] }}>
                                                            <HistoryIcon />
                                                        </Avatar>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography variant='subtitle2' color="textSecondary">
                                        {props.selectedFlightInfo.businessPartnerId + " " + props.selectedFlightInfo.id}
                                    </Typography>
                                </Grid>
                            </Grid>
                            :
                            <Grid container item spacing={1} direction="column">
                                <Grid item>
                                    <FlightStatusInfo
                                        status={props.selectedFlightInfo.status}
                                        statusSize={StatusSize.Medium} />
                                </Grid>
                                <Grid item>
                                    <Typography variant='subtitle2' color="textSecondary">
                                        {props.selectedFlightInfo.businessPartnerId + " " + props.selectedFlightInfo.id}
                                    </Typography>
                                </Grid>
                            </Grid>}
                        <Grid item container spacing={2}>
                            {flightInfoTypographyGrid(
                                <ScheduleIcon sx={{ color: "#797979" }} />,
                                [{ title: "STD - STA", value: props.selectedFlightInfo.std + " - " + props.selectedFlightInfo.sta },
                                { title: "ETD - ETA", value: props.selectedFlightInfo.etd + " - " + props.selectedFlightInfo.eta }]
                            )}
                            {flightInfoTypographyGrid(
                                <RecordVoiceOverIcon sx={{ color: "#797979" }} />,
                                [{ title: "RP", value: props.selectedFlightInfo.fd.name }]
                            )}
                            {flightInfoTypographyGrid(
                                <FlightIcon sx={{ color: "#797979" }} />,
                                [{ title: "使用機材", value: props.selectedFlightInfo.drone.label },
                                { title: "最大ペイロード", value: String(props.selectedFlightInfo.drone.payload) + "g" }]
                            )}
                            {flightInfoTypographyGrid(
                                <InfoOutlinedIcon sx={{ color: "#797979" }} />,
                                [{ title: "飛行目的", value: props.selectedFlightInfo.purpose },
                                {
                                    title: "特定飛行", value: props.selectedFlightInfo.specificList
                                        ? props.selectedFlightInfo.specificList?.join("、") : "設定なし"
                                }]
                            )}
                            {flightInfoSentenceGrid(
                                <WarningAmberOutlinedIcon sx={{ color: "#797979" }} />,
                                "飛行の安全に影響のあった事項",
                                props.selectedFlightInfo.unsafeEvent
                            )}
                            {flightInfoSentenceGrid(
                                <NotesIcon sx={{ color: "#797979" }} />,
                                "備考",
                                props.selectedFlightInfo.remarks
                            )}
                        </Grid>
                        <FlightReportDialog
                            isFlightReportDialogOpen={isFlightReportDialogOpen}
                            setFlightReportDialogOpen={setFlightReportDialogOpen}
                            selectedFlightInfo={props.selectedFlightInfo}
                            report={props.selectedFlightInfo?.report}
                            setSavedFlightInfo={props.setSavedFlightInfo} />
                    </Grid >
                </Box>
                <FlightStatusChangeDialog
                    isFlightStatusDialogOpen={isFlightStatusDialogOpen}
                    setFlightStatusDialogOpen={setFlightStatusDialogOpen}
                    selectedFlightInfo={props.selectedFlightInfo}
                    setSavedFlightInfo={props.setSavedFlightInfo}
                    orderInfo={props.orderInfo} />
                <FlightInfoEditDialog
                    isFlightInfoEditDialogOpen={isFlightInfoEditDialogOpen}
                    setFlightInfoEditDialogOpen={setFlightInfoEditDialogOpen}
                    selectedFlightInfo={props.selectedFlightInfo}
                    setSavedFlightInfo={props.setSavedFlightInfo}
                    orderInfo={props.orderInfo} />
                <FlightTimelineDialog
                    flightId={props.selectedFlightInfo.id}
                    isFlightTimelineDialogOpen={isFlightTimelineDialogOpen}
                    setFlightTimelineDialogOpen={setFlightTimelineDialogOpen}
                    orderId={props.orderInfo?.orderID}
                />
            </React.Fragment>
            :
            <Grid item xs={12}>
                <Alert severity="info" >フライトを選択してください</Alert>
            </Grid>
    );
}