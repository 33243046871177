import FlightIcon from '@mui/icons-material/Flight';
import PlaceIcon from '@mui/icons-material/Place';
import ScheduleIcon from '@mui/icons-material/Schedule';
import HeightIcon from '@mui/icons-material/Height';
import { Grid, Typography } from '@mui/material';
import { Display, GeoInformation } from 'adoms-common-lib';
import React from 'react';
import { Popup } from 'react-map-gl';

type DronePopupProps = {
    dronePopupInfo: GeoInformation
    setDronePopupInfo: (value: React.SetStateAction<GeoInformation | undefined>) => void
}

/**
 * ポート情報を表示するポップアップ
 * @param props 
 * @returns 
 */
export const DronePopup: React.FC<DronePopupProps> = (props) => {
    return (
        <Popup
            longitude={props.dronePopupInfo.longitude}
            latitude={props.dronePopupInfo.latitude}
            anchor="bottom"
            onClose={() => props.setDronePopupInfo(undefined)}
            style={{ zIndex: 300 }}>
            <Grid container spacing={1} direction={"column"}>
                <Grid item container direction="row" xs>
                    <FlightIcon fontSize="small" sx={{ marginRight: "2px" }} />
                    <Typography variant='subtitle2'>
                        {props.dronePopupInfo.flightId}
                    </Typography>
                </Grid>
                <Grid item container direction="row" xs>
                    <ScheduleIcon fontSize="small" sx={{ marginRight: "2px" }} />
                    <Typography variant='subtitle2'>
                        {Display.getTimeMiniteSecondStringFromISO8601(props.dronePopupInfo.sendTime)}
                    </Typography>
                </Grid>
                <Grid item container direction="row" xs>
                    <PlaceIcon fontSize="small" sx={{ marginRight: "2px" }} />
                    <Typography variant='subtitle2'>
                        {props.dronePopupInfo.latitude + "," + props.dronePopupInfo.longitude}
                    </Typography>
                </Grid>
                <Grid item container direction="row" xs>
                    <HeightIcon fontSize="small" sx={{ marginRight: "2px" }} />
                    <Typography variant='subtitle2'>
                       {props.dronePopupInfo.altitude}m
                    </Typography>
                </Grid>
            </Grid>
        </Popup>
    )
}