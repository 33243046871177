import NotesIcon from '@mui/icons-material/Notes';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Alert, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { FlightInfo, OrderInfo } from 'adoms-common-lib';
import React, { useEffect } from "react";
import { APIConnector } from '../../connector/APIConnector';
import { LoadingMark } from '../atoms/LoadingMark';

type FlightInfoEditDialogProps = {
    isFlightInfoEditDialogOpen: boolean
    setFlightInfoEditDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
    selectedFlightInfo: FlightInfo
    setSavedFlightInfo: React.Dispatch<React.SetStateAction<boolean>>
    orderInfo?: OrderInfo
}

/**
 * フライト情報変更
 * @param props 
 * @returns 
 */
export const FlightInfoEditDialog: React.FC<FlightInfoEditDialogProps> = (props) => {

    const [etd, setEtd] = React.useState<string | undefined>(props.selectedFlightInfo?.etd);
    const [eta, setEta] = React.useState<string | undefined>(props.selectedFlightInfo?.eta);
    const [remarks, setRemarks] = React.useState<string | undefined>(props.selectedFlightInfo?.remarks);
    const [unsafeEvent, setUnsafeEvent] = React.useState<string | undefined>(props.selectedFlightInfo?.unsafeEvent ?? "");
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [directChangeEta, setDirectChangeEta] = React.useState(false);
    const [selectedFlightId, setSelectedFlightId] = React.useState<string | undefined>(undefined);
    const [isDisplayLoadingMark, setDisplayLoadingMark] = React.useState<boolean>(false);
    const [isNotifyCustomerETAETDChanged, setNotifyCustomerETAETDChanged] = React.useState<boolean>(false);

    useEffect(() => {
        setSelectedFlightId(props.selectedFlightInfo.id);
        if (selectedFlightId !== props.selectedFlightInfo.id) {
            setDefaultValue();
        };
    }, [props.selectedFlightInfo]);

    const handleFlightInfoEditDialogClose = () => {
        setDefaultValue();
        setDirectChangeEta(false);
        props.setFlightInfoEditDialogOpen(false);
    };

    const setDefaultValue = () => {
        setErrorMessage(undefined);
        setEtd(props.selectedFlightInfo?.etd);
        setEta(props.selectedFlightInfo?.eta);
        setNotifyCustomerETAETDChanged(false);
        setRemarks(props.selectedFlightInfo?.remarks);
        setUnsafeEvent(props.selectedFlightInfo?.unsafeEvent ?? "");
    }

    const disabledSaveButton = (): boolean => {
        if (etd === props.selectedFlightInfo.etd
            && eta === props.selectedFlightInfo.eta
            && remarks === props.selectedFlightInfo.remarks
            && (unsafeEvent === props.selectedFlightInfo.unsafeEvent
                || (!unsafeEvent && !props.selectedFlightInfo.unsafeEvent))) {
            return true;
        } else {
            let flightDate = props.selectedFlightInfo.flightDate.replace(/-/g, "/");
            if (etd && eta
                && (new Date(flightDate + " " + etd) <= new Date(flightDate + " " + eta))) {
                return false;
            } else {
                return true;
            }
        };
    };

    const disabledResetButton = (): boolean => {
        if (etd === props.selectedFlightInfo.etd
            && eta === props.selectedFlightInfo.eta
            && remarks === props.selectedFlightInfo.remarks
            && (unsafeEvent === props.selectedFlightInfo.unsafeEvent
                || (!unsafeEvent && !props.selectedFlightInfo.unsafeEvent))) {
            return true;
        } else {
            return false;
        };
    };

    const handleSaveFlightInfo = async () => {
        if (props.selectedFlightInfo
            && props.selectedFlightInfo.std
            && props.selectedFlightInfo.sta
            && etd
            && eta) {
            setDisplayLoadingMark(true);
            setErrorMessage(undefined);

            let flightScheduleChangeParam = {
                id: props.selectedFlightInfo.id,
                std: props.selectedFlightInfo.std,
                sta: props.selectedFlightInfo.sta,
                etd: etd,
                eta: eta,
                orderInfo: props.orderInfo,
                isNotifyCustomerETAETDChanged: isNotifyCustomerETAETDChanged,
                remarks: remarks ?? "",
                unsafeEvent: unsafeEvent ?? undefined,
                report: props.selectedFlightInfo.report
            };

            let apiConnector: APIConnector = APIConnector.instance;
            await apiConnector.putFlightScheduleChange(flightScheduleChangeParam).then(async value => {
                props.setSavedFlightInfo(true);
                props.setFlightInfoEditDialogOpen(false);
                setDisplayLoadingMark(false);
            }).catch(error => {
                console.log(error);
                setErrorMessage("フライト情報を更新できませんでした。");
                setDisplayLoadingMark(false);
            });
        };
    };

    const flightInfoTextFieldGrid = (icon: JSX.Element, title: string, onChange: React.Dispatch<React.SetStateAction<string | undefined>>, value?: string) => {
        return (
            <Grid item container spacing={2} direction={"row"} flexWrap="nowrap">
                <Grid item>
                    {icon}
                </Grid>
                <Grid item container direction={"column"}>
                    <Grid item>
                        <Typography color="textSecondary">{title}</Typography>
                    </Grid>
                    <Grid item >
                        <TextField
                            value={value}
                            multiline
                            maxRows="10"
                            variant="outlined"
                            fullWidth
                            onChange={event => onChange(event.target.value)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    };

    const handleChangeEtd = (event: any) => {
        let inputEtd = event.target.value;
        setEtd(event.target.value);

        //iPhoneでは日付にハイフンを使えないためスラッシュに置き換える
        let flightDate = props.selectedFlightInfo.flightDate.replace(/-/g, "/");
        let newEtd = new Date(flightDate + ' ' + inputEtd);
        let newEta = new Date(flightDate + ' ' + props.selectedFlightInfo.eta);

        if (!directChangeEta) {
            //出発時刻のみ入力した場合、到着時刻も変更する
            let oldEtd = new Date(flightDate + ' ' + props.selectedFlightInfo.etd);

            let diff = (newEtd.getTime() - oldEtd.getTime()) / (60 * 1000);
            newEta.setMinutes(newEta.getMinutes() + diff);
            let newEtaString = String(String('0' + newEta.getHours()).slice(-2)
                + ':' + String('0' + newEta.getMinutes()).slice(-2));

            setEta(newEtaString);
        } else {
            if (newEtd > newEta) {
                setErrorMessage("ETDよりETAが後の時間になるように設定してください。")
            } else {
                setErrorMessage(undefined);
            };
        };
    };

    const handleChangeEta = (event: any) => {
        let inputEta = event.target.value;
        //iPhoneでは日付にハイフンを使えないためスラッシュに置き換える
        let flightDate = props.selectedFlightInfo.flightDate.replace(/-/g, "/");
        let newEtd = new Date(flightDate + ' ' + props.selectedFlightInfo.etd);
        let newEta = new Date(flightDate + ' ' + inputEta);

        if (newEtd > newEta) {
            setErrorMessage("ETDよりETAが後の時間になるように設定してください。")
        } else {
            setErrorMessage(undefined);
        };

        setDirectChangeEta(true);
        setEta(inputEta);
    };

    return (
        <Dialog
            open={props.isFlightInfoEditDialogOpen}
            onClose={handleFlightInfoEditDialogClose}
        >
            <DialogTitle id="dialog-title">
                フライト情報変更
            </DialogTitle>
            <DialogContent sx={{ paddingBottom: 0 }}>
                <Grid container spacing={2}>
                    {errorMessage ?
                        <Grid item xs={12}>
                            <Alert severity="error">{errorMessage}</Alert>
                        </Grid> : undefined
                    }
                    <Grid item container spacing={2} direction={"row"} flexWrap="nowrap">
                        <Grid item>
                            <ScheduleIcon sx={{ color: "#797979" }} />
                        </Grid>
                        <Grid item container direction={"column"}>
                            <Grid item>
                                <Typography width="120px" color="textSecondary">ETD - ETA</Typography>
                            </Grid>
                            <Grid item container direction={"row"} spacing={1}>
                                <Grid item>
                                    <TextField
                                        variant="standard"
                                        type="time"
                                        value={etd}
                                        onChange={handleChangeEtd} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle1" style={{ marginTop: "5px", display: "inline-block" }}> - </Typography>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        variant="standard"
                                        type="time"
                                        value={eta}
                                        onChange={handleChangeEta} />
                                </Grid>
                            </Grid>
                            {props.orderInfo ?
                                <Grid item>
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={isNotifyCustomerETAETDChanged}
                                            onChange={(e) => { setNotifyCustomerETAETDChanged(e.target.checked) }}
                                        />}
                                        label="お客様に通知する" />
                                </Grid> : undefined
                            }
                        </Grid>
                    </Grid>
                    {flightInfoTextFieldGrid(
                        <WarningAmberOutlinedIcon sx={{ color: "#797979" }} />,
                        "飛行の安全に影響のあった事項",
                        setUnsafeEvent,
                        unsafeEvent
                    )}
                    {flightInfoTextFieldGrid(
                        <NotesIcon sx={{ color: "#797979" }} />,
                        "備考",
                        setRemarks,
                        remarks
                    )}
                </Grid>
                <LoadingMark isDisplayLoadingMark={isDisplayLoadingMark} />
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={disabledResetButton()}
                    onClick={setDefaultValue}>
                    リセット
                </Button>
                <Button
                    color="secondary"
                    disabled={disabledSaveButton()}
                    onClick={handleSaveFlightInfo}>
                    登録
                </Button>
            </DialogActions>
        </Dialog >
    )
}