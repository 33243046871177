import { Typography } from '@mui/material';
import { Port } from 'adoms-common-lib';
import React from 'react';
import { Popup } from 'react-map-gl';

type PortPopupProps = {
    port: Port
    setPortPopupInfo: React.Dispatch<React.SetStateAction<Port | undefined>>
}

/**
 * ポート情報を表示するポップアップ
 * @param props 
 * @returns 
 */
export const PortPopup: React.FC<PortPopupProps> = (props) => {
    return (
        <Popup
            longitude={Number(props.port.longitude)}
            latitude={Number(props.port.latitude)}
            anchor="bottom"
            onClose={() => props.setPortPopupInfo(undefined)}
            style={{ zIndex: 300 }}
        >
            <Typography variant='subtitle1' fontWeight={"bold"} sx={{ marginBottom: "3px" }}>
                {props.port.name}
            </Typography>
            <Typography variant='subtitle2'>
                〒{props.port.zipcode}
            </Typography>
            <Typography variant='subtitle2'>
                {props.port.prefecture}{props.port.address}
            </Typography>
            <Typography variant='caption'>
                {props.port.latitude + "," + props.port.longitude}
            </Typography>
        </Popup>
    );
}