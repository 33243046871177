import React from 'react';
import { Marker } from 'react-map-gl';
import NavigationIcon from '@mui/icons-material/Navigation';
import CircleIcon from '@mui/icons-material/Circle';
import { ShipInformation } from 'adoms-common-lib';

type AisInformationProps = {
    aisInfoList: ShipInformation[] | undefined;
    lostAisInfoList: ShipInformation[] | undefined;
    setAisPopupInfo: React.Dispatch<React.SetStateAction<ShipInformation | undefined>>;
};

export const DisplayAisInfo: React.FC<AisInformationProps> = ({ aisInfoList, lostAisInfoList, setAisPopupInfo }) => {

    const renderMarker = (aisInfo: ShipInformation, isActive: boolean) => {
        const Icon = aisInfo.courseOverGround !== 360 ? NavigationIcon : CircleIcon;
        const color = isActive ? (aisInfo.messageType != 18 ? "primary" : "secondary") : "disabled";

        return (
            <Marker
                key={`marker-${aisInfo.userId}`}
                latitude={aisInfo.latitude}
                longitude={aisInfo.longitude}
                rotation={aisInfo.courseOverGround}
                onClick={e => {
                    e.originalEvent.stopPropagation();
                    setAisPopupInfo(aisInfo);
                }}>
                <Icon color={color} />
            </Marker>
        );
    };

    return (
        <>
            {aisInfoList?.map((aisInfo) => renderMarker(aisInfo, true))}
            {lostAisInfoList?.map((lostAisInfo) => renderMarker(lostAisInfo, false))}
        </>
    );
};