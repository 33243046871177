import React from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Typography } from "@mui/material"
import { AssosiateFlightAndOrder, OrderInfo } from "adoms-common-lib"
import ReceiveStatusInfo from "../atoms/ReceiveStatusInfo"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PersonIcon from '@mui/icons-material/Person';
import FlightIcon from '@mui/icons-material/Flight';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: any) => ({
    cardHeader: {
        backgroundColor: "#00695f"
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
}));

type OrderCardProps = {
    order: OrderInfo
    assosiateFlightAndOrderList: AssosiateFlightAndOrder[]
}

export const OrderCardForOrderListView: React.FC<OrderCardProps> = (props) => {
    const { classes } = useStyles();
    const navigate = useNavigate();

    const displayFlightId = (orderId: string): string => {
        let displayFlightId = props.assosiateFlightAndOrderList.find(assosiateFlightAndOrder => {
            return assosiateFlightAndOrder.orderId === orderId
        })?.flightId
        return displayFlightId === undefined ? "未設定" : displayFlightId
    };

    /**
     * 次のページに遷移する際の処理
     * @param event 
     * @param navigate 
     */
    const handleGotoDetail = (event: any, order: OrderInfo) => {
        navigate(
            "/orderdetailview",
            { state: { orderID: order.orderID } },
        )
    }

    return (
        <Grid item key={props.order.orderID} lg={12} md={12} sm={12} xs={12}>
            <Card>
                <CardHeader
                    title={<ReceiveStatusInfo status={props.order.receiveStatus}
                        isOkinawaBlood={props.order.businessPartnerId === "OKINAWA_BLOOD"} />}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    className={classes.cardHeader}
                />
                <CardContent>
                    <Typography variant="subtitle2" color="textSecondary" component="div" style={{ marginBottom: "5px" }}>
                        {props.order.businessPartnerId + " " + props.order.orderID}
                    </Typography>
                    <Grid container direction="row">
                        <Grid item>
                            <ScheduleIcon />
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" color="textPrimary" component="div">
                                {props.order.preferredDeliveryTime !== undefined ? props.order.preferredDeliveryTime : "設定なし"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item>
                            <PersonIcon />
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" color="textPrimary" component="div">
                                {props.order.destination.lastname + " " + props.order.destination.firstname}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item>
                            <LocationOnIcon />
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" color="textPrimary" component="div">
                                {props.order.arrival.name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item>
                            <FlightIcon />
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" color="textPrimary" component="div">
                                {displayFlightId(props.order.orderID)}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button
                        style={{ color: "#00695f", marginLeft: 'auto' }}
                        onClick={(e: any) => { handleGotoDetail(e, props.order) }}>
                        詳細
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    )
}