import { Grid, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ReceiveStatus } from 'adoms-common-lib';
import React from 'react';
import { makeStyles } from 'tss-react/mui';


const ReceiveStatusColor = {
    /**
     * 配送済み
     */
    Received: "#b28704",
    /**
     * 荷物搭載済み
     */
    Loaded: "#4caf50",
    /**
     * 搭載準備中
     */
    Preparation: "#ff9800",
    /**
     * 集荷待ち
     */
    WaitingForPickup: "#ff9800",
    /**
     * 未配送
     * @deprecated
     */
    NotReceived: "#ff9800",
    /**
     * キャンセル
     */
    Cancel: "#9e9e9e",
    /**
     * 出発ポートに向けて出発
     */
    DepartForPort: "#ff9800"
} as const;
type ReceiveStatusColor = typeof ReceiveStatusColor[keyof typeof ReceiveStatusColor];




const useStyles = makeStyles()((theme: any) => ({
    /**
     * 配送済み
     */
    Received: {
        borderRadius: 3,
        backgroundColor: ReceiveStatusColor.Received,
        color: theme.palette.primary.contrastText,
        padding: "4px 10px",
    },

    /**
     * 集荷待ち
     */
    WaitingForPickup: {
        borderRadius: 3,
        backgroundColor: ReceiveStatusColor.WaitingForPickup,
        color: theme.palette.primary.contrastText,
        padding: "4px 10px",
    },

    /**
     * 荷物搭載済み
     */
    Loaded: {
        borderRadius: 3,
        backgroundColor: ReceiveStatusColor.Loaded,
        color: theme.palette.primary.contrastText,
        padding: "4px 10px",
    },

    /**
     * 荷物搭載準備中
     */
    Preparation: {
        borderRadius: 3,
        backgroundColor: ReceiveStatusColor.Preparation,
        color: theme.palette.primary.contrastText,
        padding: "4px 10px",
    },

    /**
     * 荷物搭載準備中
     */
    Cancel: {
        borderRadius: 3,
        backgroundColor: ReceiveStatusColor.Cancel,
        color: theme.palette.primary.contrastText,
        padding: "4px 10px",
    },

    /**
     * 荷物搭載準備中
     */
    DepartForPort: {
        borderRadius: 3,
        backgroundColor: ReceiveStatusColor.DepartForPort,
        color: theme.palette.primary.contrastText,
        padding: "4px 10px",
    },

    /**
     * 未配送
     * @deprecated
     */
    NotReceived: {
        borderRadius: 3,
        backgroundColor: ReceiveStatusColor.NotReceived,
        color: theme.palette.primary.contrastText,
        padding: "4px 10px",
    },

    /**
     * 未受領
     */
    unknown: {
        borderRadius: 3,
        backgroundColor: "#c62828",
        color: theme.palette.primary.contrastText,
        padding: "4px 10px",
    },

}));

type ReceiveStatusInfoProps = {
    status: ReceiveStatus,
    isOkinawaBlood?: boolean
};

/**
 * 配送物の受領状況を表示する
 * @param props status
 */
export default function ReceiveStatusInfo(props: ReceiveStatusInfoProps) {
    const { classes } = useStyles();

    let contents;
    if (props.status == ReceiveStatus.Received) {
        //配送済み
        contents = { style: classes.Received, label: "配送済み" };
    } else if (props.status == ReceiveStatus.Loaded) {
        //荷物搭載済み
        contents = { style: classes.Loaded, label: "荷物搭載済み" };
    } else if (props.status == ReceiveStatus.Preparation) {
        //荷物搭載済み
        contents = { style: classes.Preparation, label: "荷物搭載準備中" };
    } else if (props.status == ReceiveStatus.Cancel) {
        //荷物搭載済み
        contents = { style: classes.Cancel, label: "キャンセル" };
    } else if (props.status == ReceiveStatus.NotReceived) {
        //配送待ち
        contents = { style: classes.NotReceived, label: "配送待ち" };
    } else if (props.status == ReceiveStatus.WaitingForPickup) {
        //集荷待ち
        contents = { style: classes.WaitingForPickup, label: "集荷待ち" };
    } else if (props.status == ReceiveStatus.DepartForPort) {
        //出発ポートにむけて出発
        if (props.isOkinawaBlood) {
            // 沖縄血液製剤実証
            contents = { style: classes.DepartForPort, label: "血液センター出発" };
        } else {
            contents = { style: classes.DepartForPort, label: "出発ポートに向けて出発" };
        };
    } else {
        contents = { style: classes.unknown, label: "不明" };
    }

    return (
        <Grid container>
            <Grid item xs="auto">
                <Paper elevation={0} style={{ textAlign: "center", width: "100%" }}>
                    <Typography style={{ lineHeight: "2" }} className={contents.style}>{contents.label}</Typography>
                </Paper>
            </Grid>
        </Grid>
    );
}