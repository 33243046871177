import { Alert, Checkbox, Chip, FormControlLabel, Grid, Paper, TextField, Tooltip, Typography, styled } from '@mui/material';
import { blue } from '@mui/material/colors';
import { EnvSensorInfo, FlightInfo } from "adoms-common-lib";
import React, { useEffect } from 'react';
import { APIConnector } from '../../connector/APIConnector';

const EnvSensorDisplayMainTitlePaper = styled(Paper)(({ theme }) => ({
    textAlign: "center",
    padding: 1,
    backgroundColor: blue[50],
    height: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
}));

const EnvSensorDisplayTitlePaper = styled(Paper)(({ theme }) => ({
    textAlign: "center",
    padding: 4,
    backgroundColor: "#EFEFEF",
    height: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
}));

const EnvSensorDisplayPortPaper = styled(Paper)(({ theme }) => ({
    textAlign: "center",
    padding: 4,
    height: "100%",
    backgroundColor: "#EFEFEF"
}));

const EnvSensorDisplayValuePaper = styled(Paper)(({ theme }) => ({
    textAlign: "center",
    padding: 4,
    height: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
}));

const EnvSensorDisplayYellowValuePaper = styled(Paper)(({ theme }) => ({
    textAlign: "center",
    padding: 4,
    height: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#ffe3be",
    color: "#663c00"
}));

const EnvSensorDisplayRedValuePaper = styled(Paper)(({ theme }) => ({
    textAlign: "center",
    padding: 4,
    height: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#f9caca",
    color: "#5f2120"
}));

const EnvSensorInfoGetTimeChip = styled(Chip)(({ theme }) => ({
    '& .MuiChip-label': {
        fontSize: "12px"
    },
}));

type EnvSensorInfoGridProps = {
    selectedFlightInfo: FlightInfo | undefined
}

/**
 * MMセンサー表示
 */
export const EnvSensorInfoGrid: React.FC<EnvSensorInfoGridProps> = (props) => {
    const [selectedFlightId, setSelectedFlightId] = React.useState<string | undefined>(undefined);
    const [depEnvSensorInfo, setDepEnvSensorInfo] = React.useState<EnvSensorInfo | undefined>(undefined);
    const [arrEnvSensorInfo, setArrEnvSensorInfo] = React.useState<EnvSensorInfo | undefined>(undefined);
    const [depEnvSensorInfoTime, setDepEnvSensorInfoTime] = React.useState<number | undefined>(undefined);
    const [arrEnvSensorInfoTime, setArrEnvSensorInfoTime] = React.useState<number | undefined>(undefined);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [isEnterDeviceId, setEnterDeviceId] = React.useState<boolean>(false);
    const [depDeviceId, setDepDeviceId] = React.useState<string | undefined>(undefined);
    const [arrDeviceId, setArrDeviceId] = React.useState<string | undefined>(undefined);
    const [depDeviceIdState, setDepDeviceIdState] = React.useState<string | undefined>(undefined);
    const [arrDeviceIdState, setArrDeviceIdState] = React.useState<string | undefined>(undefined);

    /**
     * 環境センサー情報を取得する
     */
    useEffect(() => {
        setSelectedFlightId(props.selectedFlightInfo?.id);
        // 異なる環境センサー情報を取得する必要がある場合、値を空にする
        if (props.selectedFlightInfo?.id !== selectedFlightId) {
            setDepEnvSensorInfo(undefined);
            setDepEnvSensorInfoTime(undefined);
            setDepDeviceId(undefined);
            setDepDeviceIdState(undefined);

            setArrEnvSensorInfo(undefined);
            setArrEnvSensorInfoTime(undefined);
            setArrDeviceId(undefined);
            setArrDeviceIdState(undefined);
        };
        fetchEnvSensorData();
    }, [props.selectedFlightInfo, depDeviceId, arrDeviceId]);

    /**
     * 1時間以内の環境センサー情報を取得する
     */
    const fetchEnvSensorData = async () => {
        console.log("環境センサー情報取得");
        if (depDeviceId) {
            //出発地のセンサー情報
            getEnvSensorData(setDepEnvSensorInfoTime, setDepEnvSensorInfo, depDeviceId);
        } else {
            //出発地のセンサー情報
            getEnvSensorData(setDepEnvSensorInfoTime, setDepEnvSensorInfo, props.selectedFlightInfo?.departure.envSensorDeviceID);
        };

        if (arrDeviceId) {
            //出発地のセンサー情報
            getEnvSensorData(setArrEnvSensorInfoTime, setArrEnvSensorInfo, arrDeviceId);
        } else {
            //到着地のセンサー情報
            getEnvSensorData(setArrEnvSensorInfoTime, setArrEnvSensorInfo, props.selectedFlightInfo?.arrival.envSensorDeviceID);
        };
    };

    const getEnvSensorData = async (
        setEnvSensorInfoTime: (value: React.SetStateAction<number | undefined>) => void,
        setEnvSensorInfo: (value: React.SetStateAction<EnvSensorInfo | undefined>) => void,
        envSensorDeviceID?: string) => {
        if (envSensorDeviceID) {
            let apiConnector: APIConnector = APIConnector.instance;
            await apiConnector.getEnvSensorData(envSensorDeviceID).then((envSensorInfo) => {
                setErrorMessage(undefined);
                const envSensorDiffMinutes = calculateEnvSensorGetTime(roundOffEnvSensorData(envSensorInfo));
                if (typeof envSensorDiffMinutes !== "undefined") {
                    setEnvSensorInfoTime(envSensorDiffMinutes);
                    setEnvSensorInfo(envSensorInfo);
                } else {
                    setEnvSensorInfoTime(undefined);
                    setEnvSensorInfo(undefined);
                };
            }).catch(e => {
                console.log(e);
                setErrorMessage("気象センサーの値が取得できませんでした。");
            });
        } else {
            setEnvSensorInfoTime(undefined);
            setEnvSensorInfo(undefined);
        };
    };

    /**
     * 環境センサーの気圧、風速、最大瞬間風速の値の小数点以下第二位を四捨五入する
     * @param envSensorInfo 
     */
    const roundOffEnvSensorData = (envSensorInfo: EnvSensorInfo): EnvSensorInfo => {
        console.log(envSensorInfo);
        const place = 10;
        envSensorInfo.pres = (Math.round(envSensorInfo.pres * place)) / place;
        envSensorInfo.wind_vel = (Math.round(envSensorInfo.wind_vel * place)) / place;
        envSensorInfo.wind_maxinst_vel = (Math.round(envSensorInfo.wind_maxinst_vel * place)) / place;
        return envSensorInfo;
    }

    /**
     * 気象センサーから取得した情報が1時間以内に取得した情報の場合、
     * 何分前に取得したか時間を返す
     * @param envSensorInfo 
     */
    const calculateEnvSensorGetTime = (envSensorInfo: EnvSensorInfo): number | undefined => {
        const regexTimestampPattern: RegExp = /^(\d{4})\/(\d{2})\/(\d{2})\s(\d{2}):(\d{2}):(\d{2})$/;
        if (regexTimestampPattern.test(envSensorInfo.timestamp)) {
            // 今日の取得データの場合、現在時刻との差分を分単位で取得する
            const envSensorDiffMinutes = Math.floor((new Date().getTime() - new Date(envSensorInfo.timestamp).getTime()) / (1000 * 60));
            if (envSensorDiffMinutes <= 60) {
                // 1時間以内に取得したデータであれば、時間を返す
                return envSensorDiffMinutes;
            };
        };
    };

    const displayEnvSensorValueGrid = (label: string, isSamePort: boolean, departureValue?: number, arrivalValue?: number) => {
        return (
            <Grid item container spacing={1} justifyContent="space-between" wrap={"nowrap"}>
                <Grid item xs>
                    <EnvSensorDisplayTitlePaper elevation={0}>
                        <Typography variant='subtitle2'>{label}</Typography>
                    </EnvSensorDisplayTitlePaper>
                </Grid>
                <Grid item xs>
                    <EnvSensorDisplayValuePaper elevation={0} variant="outlined" >
                        <Typography variant='subtitle2'>{typeof departureValue !== "undefined" ? departureValue : "ー"}</Typography>
                    </EnvSensorDisplayValuePaper>
                </Grid>
                {!isSamePort ?
                    <Grid item xs>
                        <EnvSensorDisplayValuePaper elevation={0} variant="outlined" >
                            <Typography variant='subtitle2'>{typeof arrivalValue !== "undefined" ? arrivalValue : "ー"}</Typography>
                        </EnvSensorDisplayValuePaper>
                    </Grid> : undefined}
            </Grid>
        )
    };

    const displayEnvSensorWindValueGrid = (label: string, isSamePort: boolean, departureValue?: number, arrivalValue?: number) => {

        return (
            <Grid item container spacing={1} justifyContent="space-between" wrap={"nowrap"}>
                <Grid item xs>
                    <EnvSensorDisplayTitlePaper elevation={0}>
                        <Typography variant='subtitle2'>{label}</Typography>
                    </EnvSensorDisplayTitlePaper>
                </Grid>
                {changeColorValueGrid(7, 10, 10, departureValue)}
                {!isSamePort ?
                    changeColorValueGrid(7, 10, 10, arrivalValue) : undefined}
            </Grid>
        )
    };

    const displayEnvSensorRainValueGrid = (label: string, isSamePort: boolean, departureValue?: number, arrivalValue?: number) => {

        return (
            <Grid item container spacing={1} justifyContent="space-between" wrap={"nowrap"}>
                <Grid item xs>
                    <EnvSensorDisplayTitlePaper elevation={0}>
                        <Typography variant='subtitle2'>{label}</Typography>
                    </EnvSensorDisplayTitlePaper>
                </Grid>
                {changeColorValueGrid(1, 5, 5, departureValue)}
                {!isSamePort ? changeColorValueGrid(1, 5, 5, arrivalValue) : undefined}
            </Grid>
        )
    };

    const changeColorValueGrid = (yellowLowerLimit: number, yellowUpperLimit: number, redLowerLimit: number, value?: number) => {
        if (value && isWithinRange(value, yellowLowerLimit, yellowUpperLimit)) {
            // 黄色で表示する
            return (
                <Grid item xs>
                    <EnvSensorDisplayYellowValuePaper elevation={0} variant="outlined" >
                        <Typography variant='subtitle2' sx={{ fontWeight: "bold" }}>{typeof value !== "undefined" ? value : "ー"}</Typography>
                    </EnvSensorDisplayYellowValuePaper>
                </Grid>
            );
        } else if (value && isWithinRange(value, redLowerLimit)) {
            // 赤色で表示する
            return (
                <Grid item xs>
                    <EnvSensorDisplayRedValuePaper elevation={0} variant="outlined">
                        <Typography variant='subtitle2' sx={{ fontWeight: "bold" }}>{typeof value !== "undefined" ? value : "ー"}</Typography>
                    </EnvSensorDisplayRedValuePaper>
                </Grid>
            );
        } else {
            return (
                <Grid item xs>
                    <EnvSensorDisplayValuePaper elevation={0} variant="outlined" >
                        <Typography variant='subtitle2'>{typeof value !== "undefined" ? value : "ー"}</Typography>
                    </EnvSensorDisplayValuePaper>
                </Grid>
            );
        }
    }

    /**
     * 指定された範囲内かどうか判定する(lowerLimit以上upperLimit未満)
     * @param lowerLimit 
     * @param upperLimit 
     * @param value 
     * @returns true:範囲内/false:範囲外
     */
    const isWithinRange = (value: number, lowerLimit: number, upperLimit?: number): boolean => {
        if (lowerLimit <= value) {
            if (upperLimit) {
                if (value < upperLimit) {
                    return true;
                } else {
                    return false;
                };
            } else {
                return true;
            };
        } else {
            return false;
        };
    };

    const displayPortTitlePaper = (title: string, envSensorInfo?: EnvSensorInfo, envSensorInfoTime?: number) => {
        return (
            <EnvSensorDisplayPortPaper elevation={0}>
                <Tooltip title={envSensorInfo?.deviceID ? "デバイスID : " + envSensorInfo?.deviceID : "デバイスID設定なし"}>
                    <Typography variant='subtitle2'>{title}</Typography>
                </Tooltip>
                {typeof envSensorInfoTime !== "undefined" ?
                    <Tooltip title={envSensorInfo?.timestamp}>
                        <EnvSensorInfoGetTimeChip size='small' color='primary' variant='outlined' label={envSensorInfoTime + "分前"} />
                    </Tooltip> : undefined}
            </EnvSensorDisplayPortPaper>
        )
    };

    return (props.selectedFlightInfo ?
        <Grid container spacing={2}>
            {errorMessage ?
                <Grid item xs>
                    <Alert severity="error" >{errorMessage}</Alert>
                </Grid> : undefined
            }
            <Grid item container direction={'row'}>
                <Grid item>
                    <FormControlLabel control={<Checkbox size='small'
                        checked={isEnterDeviceId}
                        onChange={
                            () => {
                                setEnterDeviceId(!isEnterDeviceId)
                                setDepDeviceId(undefined);
                                setArrDeviceId(undefined);
                                setDepDeviceIdState(undefined);
                                setArrDeviceIdState(undefined);
                            }
                        } />}
                        label={<Typography variant='subtitle2'>デバイスIDを指定する</Typography>} />
                </Grid>
                {isEnterDeviceId ?
                    <Grid item container spacing={1} direction="row" xs={12}>
                        <Grid item xs={4}>
                            <TextField
                                onBlur={(e) => setDepDeviceId(e.target.value)}
                                onChange={(e) => setDepDeviceIdState(e.target.value)}
                                value={depDeviceIdState ?? props.selectedFlightInfo.departure.envSensorDeviceID}
                                size='small' label={<Typography variant='subtitle2'>離陸地点</Typography>} variant="outlined" />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                onBlur={(e) => setArrDeviceId(e.target.value)}
                                onChange={(e) => setArrDeviceIdState(e.target.value)}
                                value={arrDeviceIdState ?? props.selectedFlightInfo.arrival.envSensorDeviceID}
                                size='small' label={<Typography variant='subtitle2'>着陸地点</Typography>} variant="outlined" />
                        </Grid>
                    </Grid>
                    : undefined}
            </Grid>
            {isEnterDeviceId
                || props.selectedFlightInfo.arrival.id !== props.selectedFlightInfo.departure.id
                ?
                <Grid item container spacing={0.5}>
                    <Grid item container spacing={1} justifyContent="space-between" wrap={"nowrap"}>
                        <Grid item xs>
                            <EnvSensorDisplayMainTitlePaper elevation={0}>
                                <Typography color="primary" variant='subtitle2'>MMセンサー</Typography>
                            </EnvSensorDisplayMainTitlePaper>
                        </Grid>
                        <Grid item xs>
                            {displayPortTitlePaper("離陸地点", depEnvSensorInfo, depEnvSensorInfoTime)}
                        </Grid>
                        <Grid item xs>
                            {displayPortTitlePaper("着陸地点", arrEnvSensorInfo, arrEnvSensorInfoTime)}
                        </Grid>
                    </Grid>
                    {displayEnvSensorValueGrid("気圧（hPa）", false, depEnvSensorInfo?.pres, arrEnvSensorInfo?.pres)}
                    {displayEnvSensorValueGrid("風向（deg）", false, depEnvSensorInfo?.wind_dir, arrEnvSensorInfo?.wind_dir)}
                    {displayEnvSensorWindValueGrid("風速（m/s）", false, depEnvSensorInfo?.wind_vel, arrEnvSensorInfo?.wind_vel)}
                    {displayEnvSensorWindValueGrid("最大瞬間風速（m/s）", false, depEnvSensorInfo?.wind_maxinst_vel, arrEnvSensorInfo?.wind_maxinst_vel)}
                    {displayEnvSensorRainValueGrid("レインレベル", false, depEnvSensorInfo?.rain, arrEnvSensorInfo?.rain)}
                </Grid>
                :
                <Grid item container spacing={0.5}>
                    <Grid item container spacing={1} justifyContent="space-between" wrap={"nowrap"}>
                        <Grid item xs>
                            <EnvSensorDisplayMainTitlePaper elevation={0}>
                                <Typography color="primary" variant='subtitle2'>MMセンサー</Typography>
                            </EnvSensorDisplayMainTitlePaper>
                        </Grid>
                        <Grid item xs>
                            {displayPortTitlePaper("離発着地点", depEnvSensorInfo, depEnvSensorInfoTime)}
                        </Grid>
                    </Grid>
                    {displayEnvSensorValueGrid("気圧（hPa）", true, depEnvSensorInfo?.pres)}
                    {displayEnvSensorValueGrid("風向（deg）", true, depEnvSensorInfo?.wind_dir)}
                    {displayEnvSensorWindValueGrid("風速（m/s）", true, depEnvSensorInfo?.wind_vel)}
                    {displayEnvSensorWindValueGrid("最大瞬間風速（m/s）", true, depEnvSensorInfo?.wind_maxinst_vel)}
                    {displayEnvSensorRainValueGrid("レインレベル", true, depEnvSensorInfo?.rain)}
                </Grid>
            }
        </Grid>
        :
        <Alert severity="info" >フライトを選択すると気象センサー情報が表示されます</Alert>
    )
};