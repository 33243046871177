import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import { Alert, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Tooltip } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { BusinessPartnerInfo } from "adoms-common-lib";
import axios from "axios";
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect } from "react";
import { APIConnector } from "../../connector/APIConnector";
import { SixSecondsDisplaySnackbar } from "../atoms/SixSecondsDisplaySnackbar";

type CreateMapURLProps = {
    isCreateMapURLDialogOpen: boolean;
    handleClickCreateMapURLDialogClose: () => void;
    setCreateMapURL?: React.Dispatch<React.SetStateAction<boolean>>
}

/**
 * マップURL作成ダイアログ
 */
export const CreateMapURLDialog: React.FC<CreateMapURLProps> = (props) => {

    const [selectedBusinessPartnerId, setSelectedBusinessPartnerId] = React.useState<string>("");
    const [expirationDateTime, setExpirationDateTime] = React.useState<Dayjs | undefined>(dayjs());
    const [remarks, setRemarks] = React.useState<string>();
    const [allBusinessPartnerInfoList, setAllBusinessPartnerInfoList] = React.useState<BusinessPartnerInfo[]>([]);
    const [businessPartnerInfoError, setBusinessPartnerInfoError] = React.useState<string | undefined>(undefined);
    const [mapURLError, setMapURLError] = React.useState<string | undefined>(undefined);
    const [mapURL, setMapURL] = React.useState<string | undefined>(undefined);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [infoMessage, setInfoMessage] = React.useState<string>();
    const [isDisplayLoadingMark, setDisplayLoadingMark] = React.useState<boolean>(false);

    useEffect(() => {
        const fetchAllBusinessPartnerInfoList = async () => {
            let c: APIConnector = APIConnector.instance;
            await c.getBusinessPartnerInfoList().then((businessPartnerInfoList: BusinessPartnerInfo[]) => {
                setAllBusinessPartnerInfoList(businessPartnerInfoList);
            }).catch((error) => {
                console.log(error);
                setBusinessPartnerInfoError("ビジネスパートナー情報を取得できませんでした。");
            });
        };
        fetchAllBusinessPartnerInfoList();
    }, []);

    /**
     * パートナーIDが変更された時
     * @param event 
     */
    const handlePartnerIdChange = (event: SelectChangeEvent<string>) => {
        setSelectedBusinessPartnerId(event.target.value ? event.target.value as string : "");
    };

    /**
     * 有効期限が変更された時
     * @param event 
     */
    const handleExpirationDateTimeChange = (newValue: Dayjs | null) => {
        if (newValue) {
            setExpirationDateTime(newValue);
        };
    };

    /**
     * 備考が変更された時
     * @param event 
     */
    const handleRemarksChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRemarks(event.target.value);
    };

    /**
     * MAPURL作成ボタンが押された時
     * @param event
     */
    const handleClickCreateMapURL = async () => {
        setMapURLError(undefined);
        setInfoMessage(undefined);
        setDisplayLoadingMark(true);

        if (!selectedBusinessPartnerId) {
            setMapURLError("ビジネスパートナーが存在しません");
            setDisplayLoadingMark(false);
        } else if (dayjs().isSame(expirationDateTime) || dayjs().isAfter(expirationDateTime)) {
            setMapURLError("有効期限は未来の日付を指定してください");
            setDisplayLoadingMark(false);
        } else {
            const ISO8601ExpirationDateTime = expirationDateTime?.format('YYYY-MM-DD')
                + "T" + expirationDateTime?.format('HH:mm') + ":00.000+09:00";

            let c: APIConnector = APIConnector.instance;
            if (selectedBusinessPartnerId && ISO8601ExpirationDateTime) {
                await c.putMapURLInformation(selectedBusinessPartnerId, ISO8601ExpirationDateTime, remarks).then((mapURL: string) => {
                    setMapURL(mapURL);
                    setInfoMessage("URLを作成しました");
                    if (props.setCreateMapURL) {
                        props.setCreateMapURL(true);
                    };
                    setDisplayLoadingMark(false);
                }).catch((error) => {
                    console.log(error);
                    setDisplayLoadingMark(false);
                    if (axios.isAxiosError(error)
                        && typeof error.response !== "undefined"
                        && error.response.status === 403) {
                        setMapURLError("本ビジネスパートナーは選択できません");
                    } else {
                        setMapURLError("URLの作成に失敗しました");
                    };
                });
            };
        };
    };

    /**
     * クリップボードにコピーする
     */
    const handleCopyToClipboard = async () => {
        if (mapURL) {
            await global.navigator.clipboard.writeText(mapURL);
            setSnackbarOpen(true);
        }
    };

    return (
        <Dialog
            open={props.isCreateMapURLDialogOpen}
            onClose={props.handleClickCreateMapURLDialogClose}
        >
            <DialogTitle id="form-dialog-title">マップURL作成</DialogTitle>
            <DialogContent sx={{ paddingBottom: 0, width: 300 }}>
                <Grid container spacing={2}>
                    <Grid item container direction="column" spacing={2}>
                        {infoMessage ?
                            <Grid item xs>
                                <Alert
                                    severity="info"
                                    action={
                                        <Tooltip title="URLをコピー">
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={
                                                    () => handleCopyToClipboard()
                                                }
                                            >
                                                <ContentCopyIcon fontSize="inherit" />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                >{infoMessage}</Alert>
                            </Grid>
                            : undefined
                        }
                        {mapURLError ?
                            <Grid item xs>
                                <Alert
                                    severity="error"
                                >{mapURLError}</Alert>
                            </Grid>
                            : undefined
                        }
                        {businessPartnerInfoError ?
                            <Grid item xs>
                                <Alert
                                    severity="error"
                                >{businessPartnerInfoError}</Alert>
                            </Grid>
                            : undefined
                        }
                        <Grid item xs textAlign={"center"}>
                            <FormControl sx={{ width: 250, marginTop: 1 }} fullWidth>
                                <InputLabel
                                    id="partner-id-select-label"
                                    required>パートナーID</InputLabel>
                                <Select
                                    sx={{ textAlign: "left" }}
                                    labelId="partner-id-select-label"
                                    label="パートナーID"
                                    id="partner-id-select"
                                    value={selectedBusinessPartnerId}
                                    onChange={handlePartnerIdChange}
                                    required>
                                    {allBusinessPartnerInfoList.map((businessPartnerInfo, i) => (
                                        <MenuItem
                                            key={i}
                                            value={businessPartnerInfo.businessPartnerId}>
                                            {businessPartnerInfo.businessPartnerId}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs textAlign={"center"}>
                            <FormControl sx={{ width: 250, marginTop: 1 }} fullWidth>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}>
                                    {/*
                                        inputformatに日本語を使用すると
                                        直接入力時に値が正しく入らない
                                    */}
                                    <DateTimePicker
                                        label="有効期限"
                                        format="YYYY/MM/DD HH:mm"
                                        value={expirationDateTime}
                                        onChange={handleExpirationDateTimeChange}
                                        disablePast
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs textAlign={"center"}>
                            <FormControl sx={{ width: 250, marginTop: 1 }} fullWidth>
                                <TextField
                                    id="remarks-text-field"
                                    label="備考"
                                    multiline
                                    rows={2}
                                    value={remarks}
                                    onChange={event => handleRemarksChange(event)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ margin: "16px" }}>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    startIcon={<LinkIcon />}
                    onClick={() => handleClickCreateMapURL()}>
                    URL作成
                </Button>
            </DialogActions>
            <SixSecondsDisplaySnackbar
                message={"URLをコピーしました"}
                snackbarOpen={snackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
            />
            <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}
                open={isDisplayLoadingMark}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    )
}