import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FlagIcon from '@mui/icons-material/Flag';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import StoreIcon from '@mui/icons-material/Store';
import { Alert, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FilledInput, FormControl, Grid, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, SelectChangeEvent, SvgIcon, TextField, Typography } from '@mui/material';
import { BusinessPartnerInfo, Display, DroneInfo, OrderInfo, Port, Purpose, Specific, UserInfo } from 'adoms-common-lib';
import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DroneIcon } from "../../assets/icons/quadcopter.svg";
import { InputValueType, getArrivalPortList, getDeparturePortList, getDroneInfoList, getFdUserInfoList, getNowTimeForSTD, getVisibleBusinessPartnerInfoList, isDisableSaveButton, putNewFlight } from '../../common/FlightCreateUtil';
import { APIConnector } from '../../connector/APIConnector';
import { LoadingMark } from '../atoms/LoadingMark';

type FlightCreateDialogProps = {
    isFlightCreateDialogOpen: boolean,
    setFlightCreateDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
    setNewFlightId?: React.Dispatch<React.SetStateAction<string | undefined>>
    setSavedFlightInfo?: React.Dispatch<React.SetStateAction<boolean>>
    userInfo?: UserInfo
    allPortList?: Port[]
    orderInfo?: OrderInfo
}

export const FlightCreateDialog: React.FC<FlightCreateDialogProps> = (props) => {

    const navigate = useNavigate();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            },
        },
    };
    const FlightPurposeList = Object.values(Purpose);
    const FlightSpecificList = Object.values(Specific);

    const [newFlightParam, setNewFlightParam] = React.useState({
        flightDate: Display.getNowDateWithString(),
        std: "",
        sta: "",
        arrivalPortId: "",
        departurePortId: "",
        fdUserSub: "",
        droneId: "",
        payload: 0,
        businessPartnerId: "",
        orderId: "",
        purpose: Purpose.Transport,
        specificList: [Specific.OutOfSight]
    } as InputValueType);
    const [businessPartnerInfoList, setBusinessPartnerInfoList] = React.useState<BusinessPartnerInfo[]>([]);
    const [departurePortList, setDeparturePortList] = React.useState<Port[]>([]);
    const [arrivalPortList, setArrivalPortList] = React.useState<Port[]>([]);
    const [displayDroneInfoList, setDisplayDroneInfoList] = React.useState<DroneInfo[]>([]);
    const [allDroneInfoList, setAllDroneInfoList] = React.useState<DroneInfo[]>([]);
    const [allFdMap, setAllFdMap] = React.useState<Map<string, UserInfo[]>>(new Map<string, UserInfo[]>());
    const [allFdUserInfoList, setAllFdUserInfoList] = React.useState<UserInfo[]>([]);
    const [fdUserInfoList, setFdUserInfoList] = React.useState<UserInfo[]>([]);
    const [fdOperationPartnerIdList, setFdOperationPartnerIdList] = React.useState<string[]>([]);
    const [fdOperationPartnerId, setfdOperationPartnerId] = React.useState<string>("");
    const [errorMessage, setErrorMessage] = React.useState<string>();
    const [isDisplayLoadingMark, setDisplayLoadingMark] = React.useState<boolean>(false);
    const [allPortList, setAllPortList] = React.useState<Port[]>(props.allPortList ?? []);

    useEffect(() => {
        const fetchInitialData = () => {
            if (props.userInfo) {
                // ビジネスパートナー情報を取得する
                const visibleBusinessPartnerInfoList = getVisibleBusinessPartnerInfoList(props.userInfo);
                setBusinessPartnerInfoList(visibleBusinessPartnerInfoList);

                if (props.orderInfo?.orderID && allPortList) {
                    // ビジネスパートナーIDをもとにポート、ドローン、FDを設定する
                    setItemByBusinessPartnerId(props.orderInfo.businessPartnerId);
                } else if (visibleBusinessPartnerInfoList.length > 0 && props.allPortList) {
                    // ビジネスパートナーIDをもとにポート、ドローン、FDを設定する
                    setItemByBusinessPartnerId(visibleBusinessPartnerInfoList[0].businessPartnerId);
                };
            };
        };
        fetchInitialData();
    }, [props.userInfo, props.allPortList, props.orderInfo, allPortList]);

    /**
     * 初回のみ全てのポート情報を取得する
     */
    useEffect(() => {
        const fetchAllPortList = async () => {
            let apiConnector: APIConnector = APIConnector.instance;
            await apiConnector.getPortListForOperation().then(async (portList: Port[]) => {
                setErrorMessage(undefined);
                setAllPortList(portList);
            }).catch((error) => {
                console.log(error);
                setErrorMessage("ポート情報を取得できませんでした。");
            });
        };
        if (allPortList.length === 0) {
            // ポート一覧がない場合取得する
            fetchAllPortList();
        }
    }, []);

    /**
     * ビジネスパートナーIDが変更された際に、設定するポート、ドローン、FDを更新する
     * @param businessPartnerId 
     */
    const setItemByBusinessPartnerId = async (businessPartnerId: string) => {
        // 出発ポートリストを取得する
        let departurePortListParam = getDeparturePortList(allPortList, businessPartnerId, setDeparturePortList);

        let arrivalPortListParam: Port[] = new Array();
        // 到着ポートリストを取得する
        if (departurePortListParam.length > 0) {
            if (props.orderInfo?.departure.id) {
                const departurePortInfo = allPortList.find(
                    port => { return port.id === props.orderInfo?.departure.id });
                if (departurePortInfo) {
                    arrivalPortListParam =
                        getArrivalPortList(departurePortInfo, allPortList, businessPartnerId, setArrivalPortList);
                } else {
                    throw new Error("出発ポート情報が取得できませんでした");
                };
            } else {
                arrivalPortListParam =
                    getArrivalPortList(departurePortListParam[0], allPortList, businessPartnerId, setArrivalPortList);
            };
        };

        // ドローンリストを取得する
        const displayDroneInfoListParam = await getDroneInfoList(
            businessPartnerId, allDroneInfoList, setAllDroneInfoList, setDisplayDroneInfoList);

        // FDリストを取得し、ログインユーザーをFDに設定するか判定する
        const isFd = await getFdUserInfoList(businessPartnerId,
            allFdUserInfoList, setAllFdUserInfoList, setAllFdMap,
            setFdUserInfoList, setFdOperationPartnerIdList, setfdOperationPartnerId, props.userInfo);

        // newFlightParamに値を設定する
        if (props.orderInfo) {
            setNewFlightParam({
                ...newFlightParam,
                businessPartnerId: businessPartnerId,
                orderId: props.orderInfo.orderID,
                std: getNowTimeForSTD(),
                departurePortId: props.orderInfo.departure.id,
                arrivalPortId: props.orderInfo.arrival.id,
                droneId: displayDroneInfoListParam.length > 0 ? displayDroneInfoListParam[0].id : "",
                fdUserSub: isFd ? props.userInfo!.sub : ""
            });
        } else {
            setNewFlightParam({
                ...newFlightParam,
                businessPartnerId: businessPartnerId,
                std: getNowTimeForSTD(),
                departurePortId: departurePortListParam.length > 0 ? departurePortListParam[0].id : "",
                arrivalPortId: arrivalPortListParam.length > 0 ? arrivalPortListParam[0].id : "",
                droneId: displayDroneInfoListParam.length > 0 ? displayDroneInfoListParam[0].id : "",
                fdUserSub: isFd ? props.userInfo!.sub : ""
            });
        };
    };

    const changeDeparturePortId = (departurePortId: string) => {
        const departnerPort = allPortList.find((port => {
            return port.id === departurePortId
        }));
        let arrivalPortListParam: Port[] = new Array();
        if (departnerPort) {
            arrivalPortListParam =
                getArrivalPortList(departnerPort, allPortList,
                    newFlightParam.businessPartnerId, setArrivalPortList);
        };
        // newFlightParamに値を設定する
        setNewFlightParam({
            ...newFlightParam,
            departurePortId: departurePortId,
            arrivalPortId: arrivalPortListParam.length > 0 ? arrivalPortListParam[0].id : "",
        });
    };

    const changeOperationPartnerId = (operationPartnerId: string) => {
        setfdOperationPartnerId(operationPartnerId);
        setFdUserInfoList(allFdMap.get(operationPartnerId) ?? []);
    };

    const changeDroneId = (droneId: string) => {
        const payload = allDroneInfoList.find((droneInfo) => {
            return droneInfo.id === droneId
        })?.payload;
        setNewFlightParam({
            ...newFlightParam,
            droneId: droneId,
            payload: payload ?? 0
        });
    };

    const saveButtonHandler = async () => {
        setErrorMessage(undefined);
        if (new Date(newFlightParam.flightDate + " " + newFlightParam.std) >
            new Date(newFlightParam.flightDate + " " + newFlightParam.sta)) {
            setErrorMessage("STAはSTDより未来の時間を設定してください");
        } else {
            await putNewFlight(newFlightParam,
                props.setFlightCreateDialogOpen,
                setDisplayLoadingMark,
                props.setSavedFlightInfo).then(newFlightId => {
                    if (props.orderInfo) {
                        navigate("/flightoperationview", {
                            state: { id: newFlightId },
                        });
                    };
                    if (props.setNewFlightId) {
                        props.setNewFlightId(newFlightId)
                    };
                }).catch((error: Error) => {
                    console.log(error);
                    setErrorMessage("フライトの作成に失敗しました");
                });
        };
    };

    const displayTitle = (icon: JSX.Element, title: string) => {
        return (
            <Grid item container direction="row" spacing={1} marginTop={1}>
                <Grid item>
                    {icon}
                </Grid>
                <Grid item>
                    <Typography>{title}</Typography>
                </Grid>
            </Grid>
        );
    };

    const displayPortItem = (label: string, value: string, portList: Port[],
        onChangeParam: ((event: SelectChangeEvent<string>, child: React.ReactNode) => void) | undefined) => {
        return (
            <FormControl variant="filled" size='small' sx={{ width: "200px" }}>
                <InputLabel>{label}</InputLabel>
                <Select
                    variant="filled"
                    value={value}
                    label={label}
                    onChange={onChangeParam}>
                    {portList?.map((port, i) => (
                        <MenuItem key={port.id} value={port.id}>{port.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    const displayFlightTimeItem = (label: string, value: string,
        onChangeParam: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void) => {
        return (
            <FormControl variant="filled" size='small' sx={{ width: "200px" }}>
                <TextField
                    size="small"
                    variant="filled"
                    type="time"
                    value={value}
                    label={label}
                    onChange={onChangeParam}
                    InputLabelProps={{
                        shrink: true,
                    }} />
            </FormControl>
        );
    };

    const displayFDItem = () => {
        return (
            <FormControl variant="filled" size='small' sx={{ width: "200px" }}>
                <InputLabel>リモートパイロット</InputLabel>
                <Select
                    variant="filled"
                    value={newFlightParam.fdUserSub}
                    label="リモートパイロット"
                    onChange={e => {
                        setNewFlightParam({
                            ...newFlightParam,
                            fdUserSub: e.target.value
                        });
                    }}>
                    {fdUserInfoList.map((fdUserInfo, i) => (
                        <MenuItem
                            key={fdUserInfo.sub}
                            value={fdUserInfo.sub}>
                            {fdUserInfo.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    return (
        <Dialog
            open={props.isFlightCreateDialogOpen}
            onClose={() => props.setFlightCreateDialogOpen(false)}
            sx={{ width: "auto" }}>
            <DialogTitle sx={{ fontWeight: "bold", paddingBottom: 0 }}>
                フライト作成
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1} padding={1} direction="column">
                    {errorMessage ?
                        <Grid item>
                            <Alert severity="error">{errorMessage}</Alert>
                        </Grid> : undefined}
                    {displayTitle(<CalendarTodayIcon fontSize='small' />, "フライト日時")}
                    <Grid item direction="row" spacing={1} alignItems="center">
                        <TextField
                            size='small'
                            variant="filled"
                            id="date"
                            label="配送日"
                            type="date"
                            value={newFlightParam.flightDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={e => {
                                setNewFlightParam({
                                    ...newFlightParam,
                                    flightDate: e.target.value
                                });
                            }}
                            sx={{ width: "200px" }} />
                    </Grid>
                    <Grid item container direction="row" spacing={1} alignItems="center">
                        <Grid item>
                            {displayFlightTimeItem("STD", newFlightParam.std,
                                (e) => {
                                    setNewFlightParam({
                                        ...newFlightParam,
                                        std: e.target.value
                                    })
                                })}
                        </Grid>
                        <Grid item>
                            {displayFlightTimeItem("STA", newFlightParam.sta, (e) => {
                                setNewFlightParam({
                                    ...newFlightParam,
                                    sta: e.target.value
                                })
                            })}
                        </Grid>
                    </Grid>
                    {displayTitle(<StoreIcon fontSize='small' />, "ビジネスパートナー")}
                    <Grid item direction="row" spacing={1} alignItems="center">
                        <FormControl variant="filled" size='small' sx={{ width: "200px" }}>
                            <InputLabel>ビジネスパートナー</InputLabel>
                            <Select
                                variant="filled"
                                value={newFlightParam.businessPartnerId}
                                label="ビジネスパートナー"
                                onChange={(e) => setItemByBusinessPartnerId(e.target.value)}>
                                {businessPartnerInfoList.map((businessPartnerInfo, i) => (
                                    <MenuItem
                                        key={businessPartnerInfo.businessPartnerId}
                                        value={businessPartnerInfo.businessPartnerId}>
                                        {businessPartnerInfo.businessPartnerName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {displayTitle(<FlagIcon fontSize='small' />, "ポート")}
                    <Grid item container direction="row" spacing={1} alignItems="center">
                        <Grid item>
                            {displayPortItem("出発ポート", newFlightParam.departurePortId, departurePortList,
                                (e) => { changeDeparturePortId(e.target.value) })}
                        </Grid>
                        <Grid item>
                            {displayPortItem("到着ポート", newFlightParam.arrivalPortId, arrivalPortList,
                                (e) => {
                                    setNewFlightParam({
                                        ...newFlightParam,
                                        arrivalPortId: e.target.value
                                    })
                                })}
                        </Grid>
                    </Grid>
                    {displayTitle(<InfoOutlinedIcon fontSize='small' />, "フライト情報")}
                    <Grid item container direction="row" spacing={1}>
                        <Grid item>
                            <FormControl variant="filled" size='small' sx={{ width: "200px" }}>
                                <InputLabel >飛行目的</InputLabel>
                                <Select
                                    variant="filled"
                                    value={newFlightParam.purpose}
                                    label="飛行目的"
                                    onChange={(e) => {
                                        setNewFlightParam({
                                            ...newFlightParam,
                                            purpose: e.target.value as Purpose
                                        })
                                    }}
                                    sx={{ height: "53px" }}>
                                    {FlightPurposeList.map((purpose, i) => (
                                        <MenuItem key={i} value={purpose}>{purpose}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl variant="filled" size='small' sx={{ minWidth: "200px", whiteSpace: 'nowrap' }}>
                                <InputLabel >特定飛行</InputLabel>
                                <Select
                                    variant="filled"
                                    label="特定飛行"
                                    multiple
                                    value={newFlightParam.specificList}
                                    onChange={(e) => {
                                        setNewFlightParam({
                                            ...newFlightParam,
                                            specificList: e.target.value as Specific[]
                                        })
                                    }}
                                    renderValue={(selected) => (
                                        <div style={{
                                            display: 'flex',
                                            flexWrap: 'wrap'
                                        }}>
                                            {(selected as string[]).map((value) => (
                                                <Chip
                                                    sx={{ margin: "2px" }}
                                                    size='small'
                                                    key={value}
                                                    label={value}
                                                    onMouseDown={(event) => { event.stopPropagation(); }}
                                                    onDelete={(event) => {
                                                        setNewFlightParam({
                                                            ...newFlightParam,
                                                            specificList: newFlightParam.specificList?.filter(
                                                                specific => specific !== value)
                                                        })
                                                    }} />
                                            ))}
                                        </div>
                                    )}
                                    MenuProps={MenuProps}
                                    input={<FilledInput />}>
                                    {FlightSpecificList.map((specific: Specific) => {
                                        return (
                                            <MenuItem key={specific} value={specific}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={newFlightParam.specificList?.findIndex(checkedSpecific => checkedSpecific === specific) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={specific} />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {displayTitle(
                        <SvgIcon fontSize='small'>
                            <DroneIcon />
                        </SvgIcon >, "ドローン")}
                    <Grid item container direction="row" spacing={1} alignItems="center">
                        <Grid item>
                            <FormControl variant="filled" size='small' sx={{ width: "200px" }}>
                                <InputLabel>ドローン種別</InputLabel>
                                <Select
                                    variant="filled"
                                    value={newFlightParam.droneId}
                                    label="ドローン種別"
                                    onChange={(e) => {
                                        changeDroneId(e.target.value);
                                    }}>
                                    {displayDroneInfoList.map((droneInfo, i) => (
                                        <MenuItem
                                            key={droneInfo.id}
                                            value={droneInfo.id}>
                                            {droneInfo.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {displayTitle(<RecordVoiceOverIcon fontSize='small' />, "リモートパイロット")}
                    <Grid item container direction="row" spacing={1} alignItems="center">
                        <Grid item>
                            <FormControl variant="filled" size='small' sx={{ width: "200px" }}>
                                <InputLabel>オペレーションパートナー</InputLabel>
                                <Select
                                    variant="filled"
                                    value={fdOperationPartnerId}
                                    label="オペレーションパートナー"
                                    onChange={((e) => changeOperationPartnerId(e.target.value))}
                                >
                                    {fdOperationPartnerIdList.map((fdOperationPartnerId, i) => (
                                        <MenuItem
                                            key={fdOperationPartnerId}
                                            value={fdOperationPartnerId}>
                                            {fdOperationPartnerId}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            {displayFDItem()}
                        </Grid>
                    </Grid>
                </Grid>
                <LoadingMark isDisplayLoadingMark={isDisplayLoadingMark} />
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    disabled={isDisableSaveButton(newFlightParam)}
                    onClick={saveButtonHandler}>
                    登録
                </Button>
            </DialogActions>
        </Dialog>
    )
}