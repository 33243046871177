import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { FlightTimeline } from "./FlightTimeline";

type FlightTimelineDialogProps = {
    flightId: string
    isFlightTimelineDialogOpen: boolean
    setFlightTimelineDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
    orderId?: string
}

export const FlightTimelineDialog: React.FC<FlightTimelineDialogProps> = (props) => {

    /**
     * ダイアログをクローズする
     */
    const handleNewFlightDialogClose = () => {
        props.setFlightTimelineDialogOpen(false);
    };

    return (
        <Dialog
            open={props.isFlightTimelineDialogOpen}
            onClose={handleNewFlightDialogClose}
        >
            <DialogTitle>
                操作履歴
            </DialogTitle>
            <DialogContent sx={{ minWidth: "350px" }}>
                <FlightTimeline
                    key={props.flightId}
                    flightId={props.flightId}
                    orderId={props.orderId} />
            </DialogContent>
        </Dialog>
    )
}